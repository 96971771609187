<script>
  import { Link, router } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let fixtechnic = $Data.fixtechnic[0];
  let maxheight = 0;
</script>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .inner {
    width: 100%;
    .container {
      width: 100%;
      .box {
        background: mixins.$white;
        overflow: hidden;
        &:hover {
          box-shadow: 0 0 1px 5px rgba(61, 158, 215, 0.63);
        }
        .box_img {
          position: relative;
          width: 100%;
          height: 300px;
          background-position: center;
          background-size: 82%;
          @include mixins.MQ(mobile) {
            background-size: cover;
          }
          float: left;
          border-radius: 30%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .box_desc {
          text-align: center;
          font-size: 16px;
          overflow: hidden;
          float: left;
          width: 97%;
          overflow-wrap: break-word;
          margin: 20px;
          @include mixins.MQ(mobile) {
            font-size: 14px;
            width: 90%;
            margin: 16px;
          }
          img {
            margin: 25px;
            @include mixins.MQ(mobile) {
              margin: 22px 10px 0px 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>

<div class="fullWidth inner">

  <div class="item">
    <div class="container">

      <div class="box popup" id="box_0">

        <div
          class="box_img"
          style="background-image:url(https://www.gffootballplayers.com/{fixtechnic.home[0].value.image.path})" />

        <div class="box_desc">
          <h1 class="box_head">
            {fixtechnic.home[0].value['title' + get_lang()]}
          </h1>
          {@html fixtechnic.home[0].value['description' + get_lang()]}
        </div>

      </div>
    </div>
  </div>

</div>
