<script>
  import { fade, fly } from "svelte/transition";
  import { tick } from "svelte";
  import { navigateTo } from "yrv";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang } from "@stores/app_store.js";

  let langs = $Data.langs[0];
  let currentTitleIndex = -1;
  let titles = [
    langs.RegisterNow?.title1,
    langs.RegisterNow?.title2,
    langs.RegisterNow?.title3,
    langs.RegisterNow?.title4,
  ].filter((title) => title);

  const transitions = [
    { in: fade },
    { in: fly, params: { x: 200, duration: 1000 } },
    { in: fly, params: { y: -200, duration: 800 } },
    { in: fly, params: { y: 200, duration: 900 } },
  ];

  async function cycleTitles() {
    while (true) {
      await tick(); // Wait for next DOM update
      currentTitleIndex = (currentTitleIndex + 1) % titles.length;
      await new Promise((r) => setTimeout(r, 5000)); // Show each title for 5 seconds
    }
  }
  // currentTitleIndex = (currentTitleIndex + 1) % titles.length;
  cycleTitles();
</script>

<div class="fullWidth inner">
  <video autoplay="true" nocontrols loop muted>
    <source src={langs.RegisterNow?.Videolink} type="video/mp4" />
    Your browser does not support HTML video.
  </video>

  <div class="title popup">
    <h1>
      {#each titles as title, i}
        {#if i === currentTitleIndex}
          {#if transitions[i].in === fade}
            <span out:fade in:fade>{@html title}</span>
          {:else if transitions[i].in === fly}
            <span
              out:fly={transitions[i].params}
              in:fly={transitions[i].params}
            >
              {@html title}
            </span>
          {/if}
        {/if}
      {/each}
    </h1>
  </div>

  <div class="descriptionQ appearleft">
    <div
      class="more fadein"
      on:click={() => {
        navigateTo("/contact", { replace: true });
      }}
    >
      {@html langs.RegisterNow?.button
        ? langs.RegisterNow.button
        : "ΚΑΝΕ ΕΓΓΡΑΦΗ ΤΩΡΑ"}
      <SVG key={"arrowright"} width={"10px"} />
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: 0px;
    left: 49.9%;
    transform: translateX(-50%);
    justify-content: center;
    text-align: center;
    align-content: center;
    align-items: center;
    background-position: bottom;
    height: 110vh;
    width: 100vw;
    background-size: cover;
    background-color: #000;
    z-index: 0;
    flex-direction: column;
    // &:after {
    //   content: "";
    //   position: absolute;
    //   width: 100vw;
    //   height: 100vh;
    //   background: rgba(0, 4, 255, 0.1);
    //   z-index: 0;
    //   top: 0;
    //   left: 0;
    //   pointer-events: none;
    //   mix-blend-mode: screen;
    // }
    @include mixins.MQ(mobile) {
      top: 0;
      height: 90vh;
      width: 100vw;
      &:before {
        bottom: -86px;
        height: 125px;
      }
    }

    video,
    iframe {
      position: absolute;
      width: 100%;
      height: 110%;
      top: 0;
      left: 0;
      object-fit: cover;
      background: linear-gradient(90deg, #2365b2, #461bbb);
      @include mixins.MQ(mobile) {
        top: 2vh;
        left: -30vh;
        width: 120vh;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .descriptionQ {
    font-size: 15.2px;
    line-height: 21px;
    @include mixins.MQ(mobile) {
      font-size: 13.2px;
      line-height: 16px;
      z-index: 9;
    }
  }

  :global(.bottomPart svg) {
    margin: 0 20px !important;
    height: 130px;
    @include mixins.MQ(mobile) {
      margin: 0 5px !important;
      height: 100px;
    }
  }
  .headPart {
    display: flex;
    color: white;
    justify-content: space-around;
  }

  @font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src:
      local("Open Sans"),
      local("OpenSans"),
      url("http://themes.googleusercontent.com/static/fonts/opensans/v5/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff")
        format("woff");
  }

  .descriptionQ,
  .title {
    width: 100%;
    max-width: 1040px;
    color: #fff;
    top: 0vh;
    position: relative;
    @include mixins.MQ(mobile) {
      width: 100%;
      top: 19vh;
    }
  }

  .title {
    // height: 220px;
    display: flex;
    align-content: flex-end;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    @include mixins.MQ(mobile) {
      height: 160px;
      z-index: 9;
    }
    h4 {
      line-height: 0px;
    }
    h1 {
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        flex-wrap: wrap;
        gap: 7px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 20px !important;
        backdrop-filter: blur(10px);
        background: linear-gradient(90deg, #002c5991 0%, #004d9e5c 100%);
        border-radius: 30px;
        @include mixins.MQ(mobile) {
          border-radius: 20px;
        }
        border-top: 5px solid white;
        border-bottom: 1px solid white;
        :global(h3, span b) {
          // color: #58bcff;
          margin: 0;
          text-shadow:
            -1px 0 5px #0924aac5,
            1px 0 5px #0924aa60;
          animation: swap forwards 1s;
          &.big {
            font-size: 70px;
            @include mixins.MQ(mobile) {
              font-size: 30px;
            }
          }
        }
      }
      position: relative;
      display: flex;
      align-content: flex-end;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      font-size: 60px;
      width: 100%;
      text-shadow:
        0 0 10px rgba(0, 0, 0, 0.288),
        0 0 10px #1414354d;
      height: 290px;
      line-height: 70px;
      font-weight: 100 !important;
      transform: scale(0.9);
      @include mixins.MQ(mobile) {
        font-size: 21px;
        @include mixins.BP(Ms) {
          font-size: 18px;
        }
        @include mixins.BP(Mm) {
          font-size: 20px;
        }
        line-height: 30px;
        // transform: translateY(195px);
      }
    }
  }
  .more {
    cursor: pointer;
    background: mixins.$color-1;
    padding: 10px 25px;
    z-index: 99;
    color: #fff;
    font-size: 20px;
    font-weight: 99;
    border-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    max-width: 320px;
    margin: 0 auto;
    transform: scale(0.8);
    :global(svg) {
      margin-left: 30px;
    }
  }
</style>
