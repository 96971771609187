<script>
  import IndexMain from "@views/index/index.svelte";
  import Player from "@views/player/index.svelte";
  import News from "@views/news/index.svelte";
  import Aboutus from "@views/aboutus/index.svelte";
  import Questions from "@views/questions/index.svelte";
  import Contact from "@views/contact/index.svelte";
  import Founders from "@views/founders/index.svelte";
  import Trainers from "@views/trainers/index.svelte";
  import Fixtechnic from "@views/fixtechnic/index.svelte";
  import Collabs from "@views/collaborations/index.svelte";
  import Agents from "@views/agents/index.svelte";
  import Oops from "@views/oops/index.svelte";
  import Instractor from "@components/instractor.svelte";
  /*******************************************************************************************************/
  import SVG from "@services/SVG.svelte";
  import { onMount } from "svelte";
  import { get } from "svelte/store";
  import { Router, Route, router, Link } from "yrv";
  // Router.hashchange = false;
  import { Data } from "@stores/app_store.js";
  Data.setInit(); 
  let version=$Data.Ver;
  let ConnectionStatus = null;
  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/sw.js?v="+version).then(() => {
        console.log("Service Worker Registered");
      });
    }
  };
  const CheckBrowser = () => {
    // Opera 8.0+
    var isOpera =
      (!!window.opr && !!opr.addons) ||
      !!window.opera ||
      navigator.userAgent.indexOf(" OPR/") >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== "undefined";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && safari.pushNotification)
      );

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 71
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    if (isIE) {
      window.location.href = "https://www.gffootballplayers.com/services/UnsupportedPage.html";
      return false; 
    }
    return true;
  };
  onMount(() => {
    if (CheckBrowser()) {
      registerServiceWorker();
    }
  });
  // let demo="/demo";
  let demo="";
</script>

<style>
  div#Main {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }
</style>

<svelte:window />

<div id="Main">
  {#if Object.keys($Data).length != 0}
    <Router>
      <!-- <Instractor /> -->
      <Route path="{demo}/" exact component={IndexMain} />
      <Route path="{demo}/news" exact component={News} />
      <Route path="{demo}/news/:slug" exact let:router component={News} />
      <Route path="{demo}/aboutus" exact component={Aboutus} />
      <Route path="{demo}/questions" exact component={Questions} />
      <Route path="{demo}/player" exact component={Player} />
      <Route path="{demo}/player/:slug" exact component={Player} />
      <Route path="{demo}/contact" exact component={Contact} />
      <Route path="{demo}/fixtechnic/:slug" exact let:router component={Fixtechnic} />
      <Route path="{demo}/collabs" exact component={Collabs} />
      <Route path="{demo}/founder" exact component={Founders} />
      <Route path="{demo}/founder/:slug" exact let:router component={Founders} />     
      <Route path="{demo}/trainers" exact component={Trainers} />
      <Route path="{demo}/trainers/:slug" exact let:router component={Trainers} />
      <Route path="{demo}/agents" exact component={Agents} />
      <Route path="{demo}/agents/:slug" exact let:router component={Agents} />
      <Route path="{demo}/oops" exact component={Oops} />
    </Router>
  {/if}
</div>
