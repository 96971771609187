<script>
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
</script>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .fullWidth.section2 {
    padding: 30px;
    overflow-wrap: break-word;
  }
  .title {
    position: absolute;
    color: white;
    font-size: 70px;
    font-weight: 999;
    top: 90px;
    text-shadow: 1px 1px 7px black;
    @include mixins.MQ(mobile) {
      font-size: 13vw;
      top: 160px;
    }
  }
</style>

<div class="fullWidth section2 ">
  <div class="fullWidth">
    <div class="title zoom">OOPS! SOMETHING WENT WRONG</div>
  </div>
</div>
