<script>
  import { navigateTo } from "yrv";
  import { onMount, onDestroy } from "svelte";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang, getAverageRGB } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let players = $Data.players;
  players = players.filter(function (value, index, arr) {
    return !players[index].DEMO;
  });
  let background,
    middle,
    left,
    right,
    stats,
    loop = null;
  let sliderCurrent = 0;
  onMount(() => {
    nextprev();
    loop = setInterval(() => {
      sliderCurrent += 1;
      nextprev("right", 50);
    }, 6000);
  });
  onDestroy(() => {
    clearInterval(loop);
  });

  const nextprev = (a, time) => {
    clearInterval(loop);
    loop = setInterval(() => {
      sliderCurrent += 1;
      nextprev("right", 50);
    }, 10000);
    if (!stats || !left || !right || !middle || !background) {
      clearInterval(loop);
      return;
    }
    stats.classList.remove("popup");
    stats.classList.remove("zoom");
    middle.classList.remove("zoom");

    left.classList.remove("appearleft");
    right.classList.remove("appearleft");
    left.classList.remove("appearright");
    right.classList.remove("appearright");

    setTimeout(() => {
      stats.classList.add("zoom");
      middle.classList.add("zoom");
      if (a == "left") {
        left.classList.add("appearleft");
        right.classList.add("appearleft");
      } else {
        left.classList.add("appearright");
        right.classList.add("appearright");
      }
    }, time);

    //////////----------------------------------------------
    if (sliderCurrent < 0) {
      sliderCurrent = players.length - 1;
    }
    //////////----------------------------------------------
    if (sliderCurrent > players.length - 1) {
      sliderCurrent = 0;
    }
    /////////////-------------------------------------------

    // console.log(sliderCurrent);

    left.style.backgroundImage =
      "url('https://www.gffootballplayers.com/" +
      players[sliderCurrent > 0 ? sliderCurrent - 1 : players.length - 1]
        .IconImage?.path +
      "')";
    middle.style.backgroundImage =
      "url('https://www.gffootballplayers.com/" +
      players[sliderCurrent].IconImage?.path +
      "')";
    right.style.backgroundImage =
      "url('https://www.gffootballplayers.com/" +
      players[sliderCurrent >= players.length - 1 ? 0 : sliderCurrent + 1]
        .IconImage?.path +
      "')";
    setTimeout(() => {
      let color = getAverageRGB(
        "https://www.gffootballplayers.com/" +
          players[sliderCurrent].IconImage.path
      );
      // console.log(color);
      background.style.backgroundColor = color;
    }, 100);
  };
</script>

<div
  class="Title appearleft"
  on:click={() => {
    window.location.href = "/news";
  }}
>
  <i class="far fas fa-walking" />
  {langs.Mplayers}
</div>
<div class="Background" bind:this={background}>
  <div class="overbackground" />
  <div class="Left popupslow" bind:this={left} />
  <div
    class="Middle popupfast"
    bind:this={middle}
    on:click={() => {
      navigateTo("/player/" + players[sliderCurrent]._id, { replace: true });
    }}
  />
  <div class="Right popupslow" bind:this={right} />
  <div class="name popup">
    {players[sliderCurrent]["Onoma" + get_lang()] !== ""
      ? players[sliderCurrent]["Onoma" + get_lang()]
      : "-"}
  </div>
  <div class="stats popup" bind:this={stats}>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-map-marker" />
        {langs.Pthesi}
      </div>
      <div class="bottom">
        {#each players[sliderCurrent].Position as item, i}
          {item["Fullname" + get_lang()] !== ""
            ? item["Fullname" + get_lang()]
            : "-"}
          {#if i % 2 == 0}/{/if}
        {/each}
      </div>
    </div>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-globe-americas" />
        {langs.Pethnikotita}
      </div>
      <div class="bottom">
        <img
          src="https://www.gffootballplayers.com/{players[sliderCurrent]
            .Ethnikotita.Flag.path}"
          width="30px"
          height="30px"
          alt="flag"
          style="margin:3px;object-fit: contain;"
        />
        {players[sliderCurrent].Ethnikotita["Fullname" + get_lang()] !== ""
          ? players[sliderCurrent].Ethnikotita["Fullname" + get_lang()]
          : "-"}
      </div>
    </div>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-child" />
        {langs.PHmeromiaGenisis}
      </div>
      <div class="bottom">
        {players[sliderCurrent]["HmerominiaGenisis"] !== ""
          ? players[sliderCurrent]["HmerominiaGenisis"]
          : "-"}
      </div>
    </div>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-arrows-alt-v" />
        {langs.Pypsos}
      </div>
      <div class="bottom">
        {players[sliderCurrent]["Ypsos"] !== ""
          ? players[sliderCurrent]["Ypsos"]
          : "-"}
      </div>
    </div>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-weight" />
        {langs.Pbaros}
      </div>
      <div class="bottom">
        {players[sliderCurrent]["Baros"] !== ""
          ? players[sliderCurrent]["Baros"]
          : "-"}
      </div>
    </div>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-shoe-prints" />
        {langs.Ppodi}
      </div>
      <div class="bottom">
        {players[sliderCurrent]["Podi" + get_lang()] !== ""
          ? players[sliderCurrent]["Podi" + get_lang()]
          : "-"}
      </div>
    </div>
    <div class="wrapper">
      <div class="top">
        <i class="fas fa-user-friends" />
        {langs.Pomada}
      </div>
      <div class="bottom">
        {players[sliderCurrent]["Omada" + get_lang()] !== ""
          ? players[sliderCurrent]["Omada" + get_lang()]
          : "-"}
      </div>
    </div>
  </div>
  <div
    class="more"
    on:click={() => {
      navigateTo("/player/" + players[sliderCurrent]._id, { replace: true });
    }}
  >
    {langs.PShowPlayer}
    <SVG key={"arrowright"} width={"10px"} />
  </div>
  <div
    class="leftbutton swap"
    on:click={() => {
      sliderCurrent -= 1;
      nextprev("left", 10);
    }}
  >
    <SVG key={"arrowleft"} width={"60px"} />
  </div>
  <div
    class="rightbutton swap"
    on:click={() => {
      sliderCurrent += 1;
      nextprev("right", 10);
    }}
  >
    <SVG key={"arrowright"} width={"60px"} />
  </div>
  <div class="overlay" />
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .Title {
    position: absolute;
    left: calc(50% - 175px);
    z-index: 9;
    padding: 10px 50px;
    top: -20px;
    font-size: 25px;
    font-weight: 999;
    color: #1034a6;
    background: mixins.$Nwhite;
    width: 350px;
    border-radius: 80px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;

    @include mixins.MQ(mobile) {
      padding: 8px 50px;
      top: -30px;
      font-size: 20px;
    }
  }
  .Background {
    width: 100%;
    height: 100%;
    background-color: rgb(45, 20, 156);
    transition: background-color 1s;
    display: flex;
    justify-content: center;
    align-items: center;

    .overlay {
      position: absolute;
      top: 235px;
      left: 0;
      width: 100%;
      height: 63%;
      z-index: 9;
      pointer-events: none;
      background: rgb(54, 49, 145);
      background: linear-gradient(
        0deg,
        #2b3144,
        #2b3144 5%,
        rgba(45, 64, 129, 0.95) 40%,
        rgba(35, 85, 200, 0.6) 50%,
        rgba(0, 212, 255, 0)
      );
      @include mixins.MQ(mobile) {
        background: linear-gradient(
          0deg,
          #2b3144,
          #2b3144 5%,
          rgba(45, 64, 129, 0.95) 40%,
          rgba(35, 85, 200, 0.8) 70%,
          rgba(0, 212, 255, 0)
        );
      }
    }
    .Left {
      position: relative;
      top: 50px;
      z-index: 2;
      width: 300px;
      height: 300px;
      background-size: 90%;
      background-position: top;
      background-repeat: no-repeat;
      filter: blur(1px);
      @include mixins.MQ(mobile) {
        top: 80px;
        right: 200px;
        position: absolute;
        filter: blur(3px);
      }
    }
    .Right {
      position: relative;
      top: 50px;
      z-index: 2;
      width: 300px;
      height: 300px;
      background-size: 90%;
      background-position: top;
      background-repeat: no-repeat;
      filter: blur(1px);
      @include mixins.MQ(mobile) {
        top: 80px;
        right: -110px;
        position: absolute;
        filter: blur(3px);
      }
    }
    .Middle {
      cursor: pointer;
      position: relative;
      top: 20px;
      z-index: 3;
      width: 400px;
      height: 510px;
      background-size: contain;
      background-position: top;
      @include mixins.MQ(mobile) {
        width: 100%;
        top: 50px;
        width: 400px;
        height: 100%;
        position: absolute;
        background-position: top;
        overflow: hidden;
        max-height: 330px;
      }
      background-repeat: no-repeat;
      filter: contrast(120%) drop-shadow(-1px -4px 0px cyan)
        drop-shadow(4px 0px 0px white) drop-shadow(-4px 15px 30px blue);
    }
    .overbackground {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      mix-blend-mode: screen;
      border-radius: 42px;
      background-image: url(https://www.gffootballplayers.com/images/PlayerBG.png);
      background-size: 130%;
      background-position: -118px -62px;
      opacity: 0.5;
      @include mixins.MQ(mobile) {
        background-size: 240%;
        background-position: -258px -62px;
      }
    }
    .name {
      position: absolute;
      top: 400px;
      font-size: 52px;
      @include mixins.MQ(mobile) {
        top: 314px;
        font-size: 6.5vw;
        text-shadow: 0 0 10px black;
      }
      @include mixins.BP(T) {
        top: 266px;
        font-size: 6vw;
      }
      z-index: 10;
      color: white;
      text-align: center;
      font-weight: 700;
      width: 100%;
    }
    .stats {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: absolute;
      top: 510px;
      z-index: 10;
      color: white;
      font-size: 18px;
      @include mixins.MQ(mobile) {
        top: 360px;
        left: -10px;
        @include mixins.BP(Ms) {
          left: 0px;
        }
        font-size: 13px;
        flex-wrap: wrap;
      }
      text-align: center;
      font-weight: 100;
      display: flex;
      .wrapper {
        padding: 5px 20px;
        border-right: 1px solid white;
        line-height: 23px;
        margin-top: 10px;
        @include mixins.MQ(mobile) {
          width: 33.1%;
          margin: 5px 0;
          line-height: 17px;
          padding: 0;
          &:last-child {
            width: 100%;
          }
          &:nth-child(3),
          &:nth-child(6) {
            border-right: 0px solid white;
          }
        }
        &:last-child {
          border-right: 0px solid white;
        }

        .top {
          font-size: 16px;
          @include mixins.MQ(mobile) {
            font-size: 12px;
          }
          @include mixins.BP(Ms) {
            font-size: 11px;
          }
          font-weight: 600;
        }
        .bottom {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          @include mixins.MQ(mobile) {
            font-size: 1.2rem;
            max-width: 110px;
            margin: 0 auto;
          }
        }
      }
    }
    .leftbutton {
      position: absolute;
      z-index: 79;
      height: 50px;
      left: 18vw;
      width: 50px;
      margin: 20px;
      cursor: pointer;
      @include mixins.MQ(mobile) {
        left: 0;
      }
    }
    .rightbutton {
      position: absolute;
      z-index: 79;
      height: 50px;
      right: 18vw;
      width: 50px;
      margin: 20px;
      cursor: pointer;
      @include mixins.MQ(mobile) {
        right: 0;
      }
    }
    .more {
      cursor: pointer;
      position: absolute;
      bottom: -10px;
      right: 0;
      background: mixins.$color-1;
      padding: 8px 50px;
      z-index: 99;
      color: white;
      font-size: 14px;
      font-weight: 99;
      border-radius: 50px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      transition: bottom 1s;
      @include mixins.MQ(pc) {
        padding: 10px 25px;
        bottom: 114px;
        right: 43%;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        transform: scale(0.85);
        text-shadow: 3px 3px 5px rgb(0 0 0 / 46%);
        box-shadow: 0 0 15px 0px rgb(0 0 0 / 25%);
      }
      @include mixins.BP(Ms) {
        padding: 8px 15px;
        bottom: -38px;
        right: 0;
        font-size: 12px;
      }
      @include mixins.BP(Mm) {
        padding: 8px 15px;
        bottom: -20px;
        right: 0;
        font-size: 12px;
      }
      @include mixins.BP(Ml) {
        padding: 8px 15px;
        bottom: -10px;
        right: 0;
        font-size: 12px;
      }
      &:hover {
        bottom: 112px;
      }
      :global(svg) {
        margin-left: 30px;
        @include mixins.BP(Ms) {
          margin-left: 8px;
        }
        @include mixins.BP(Mm) {
          margin-left: 8px;
        }
      }
    }
  }
</style>
