<script>
  import { navigateTo } from "yrv";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let idrites = $Data.Idrites;
  import { onMount } from "svelte";
  let TopPart, header, body, scrollablecontent, la, ra;

  var observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.intersectionRatio !== 0) {
        header.classList.add("popup");
        body.classList.add("popupslow");
      }
    },
    {
      threshold: [0, 1],
    }
  );
  onMount(() => {
    observer.observe(TopPart);
    ra.querySelector("svg").classList.add("open");
  });
  let offset = 0,
    offsetX = 320;

  const godir = (a) => {
    if (a == "r") {
      offset += offsetX;
      if (offset == offsetX) {
        la.querySelector("svg").classList.add("open");
      } else if (offset == offsetX * 2) {
        ra.querySelector("svg").classList.remove("open");
      }
      if (offset > offsetX * 2) offset = offsetX * 2;
    }
    if (a == "r" && offset <= 0) {
      offset = 0;
    }
    if (a == "l" && offset > 0) {
      offset -= offsetX;
      if (offset < 0) offset = 0;
      if (offset == 0) {
        la.querySelector("svg").classList.remove("open");
      } else {
        ra.querySelector("svg").classList.add("open");
      }
    }
    scrollablecontent.scrollTo({
      top: 0,
      left: offset,
      behavior: "smooth",
    });
  };
</script>

<div class="fullWidth inner">
  <div class="TopPart" bind:this={TopPart}>
    <div class="header" bind:this={header}>{langs.Midrites}</div>
    <div class="body" bind:this={body}>
      <div class="container" style="width: 100%; max-width:1350px; margin:0 auto;">
        {@html langs.YDescription}
      </div>
    </div>
  </div>
  <div class="_3cols_container" bind:this={scrollablecontent}>
    <div class="_3cols">
      {#each idrites.slice(0, 3) as item, i}
        {#if i % 3 == 0}
          <div
            class="MiddlePart appearleft"
            on:click={() => {
              navigateTo("/founder/" + item._id);
            }}
          >
            <div
              class="Back"
              style="background-image:url(https://www.gffootballplayers.com/{item.Big_Image
                ? item.Big_Image.path
                : ''})"
            />
            <div
              class="photo"
              style="background-image:url(https://www.gffootballplayers.com/{item.Preview_Image
                ? item.Preview_Image.path
                : ''})"
            />
            <div class="word">
              <div class="title">{item["Title" + get_lang()]}</div>
              <div class="desc">
                {@html item["ShortDescription" + get_lang()]}
              </div>
              <div class="more">{langs.Ymorebutton}</div>
            </div>
          </div>
        {:else if i % 2 == 0}
          <div
            class="MiddlePart appearleft"
            on:click={() => {
              navigateTo("/founder/" + item._id);
            }}
          >
            <div
              class="Back"
              style="background-image:url(https://www.gffootballplayers.com/{item.Big_Image
                ? item.Big_Image.path
                : ''})"
            />
            <div
              class="photo"
              style="background-image:url(https://www.gffootballplayers.com/{item.Preview_Image
                ? item.Preview_Image.path
                : ''})"
            />
            <div class="word">
              <div class="title">{item["Title" + get_lang()]}</div>
              <div class="desc">
                {@html item["ShortDescription" + get_lang()]}
              </div>
              <div class="more">{langs.Ymorebutton}</div>
            </div>
          </div>
        {:else}
          <div
            class="MiddlePart appearright"
            on:click={() => {
              navigateTo("/founder/" + item._id);
            }}
          >
            <div
              class="Back"
              style="background-image:url('https://www.gffootballplayers.com/{item.Big_Image
                ? item.Big_Image.path
                : ''}')"
            />
            <div
              class="photo"
              style="background-image:url('https://www.gffootballplayers.com/{item.Preview_Image
                ? item.Preview_Image.path
                : ''}')"
            />
            <div class="word">
              <div class="title">{item["Title" + get_lang()]}</div>
              <div class="desc">
                {@html item["ShortDescription" + get_lang()]}
              </div>
              <div class="more">{langs.Ymorebutton}</div>
            </div>
          </div>
        {/if}
      {/each}
    </div>
    <div
      class="leftbutton swap"
      on:click={() => {
        godir("l");
      }}
      bind:this={la}
    >
      <SVG key={"arrowleft"} width={"60px"} />
    </div>
    <div
      class="rightbutton swap"
      on:click={() => {
        godir("r");
      }}
      bind:this={ra}
    >
      <SVG key={"arrowright"} width={"60px"} />
    </div>
  </div>
  <div
    class="see_full_list"
    on:click={() => {
      navigateTo("/founder/");
    }}
  >
    {langs.see_full_list}
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .leftbutton {
    @include mixins.MQ(pc) {
      display: none;
    }
    @include mixins.MQ(mobile) {
      position: absolute;
      z-index: 79;
      height: 50px;
      left: 0;
      width: 50px;
      margin: 20px;
      cursor: pointer;
    }
    :global(svg.open) {
      fill: #1660dc !important;
    }
  }
  .rightbutton {
    @include mixins.MQ(pc) {
      display: none;
    }
    @include mixins.MQ(mobile) {
      position: absolute;
      z-index: 79;
      height: 50px;
      right: 0;
      width: 50px;
      margin: 20px;
      cursor: pointer;
    }
    :global(svg.open) {
      fill: #1660dc !important;
    }
  }

  ._3cols_container {
    width: 100vw;
    overflow: auto;
    ._3cols {
      width: 100%;
      min-width: 1080px;
      max-width: mixins.$maxWidth;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row;
      margin: 0 auto;
    }
  }
  .inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    padding-bottom: 30px;
  }

  .MiddlePart {
    border-bottom-left-radius: 10px !important;
  }
  .LeftPart {
    border-bottom-right-radius: 10px !important;
  }
  .RightPart {
    border-radius: 0 !important;
  }

  .RightPart,
  .LeftPart,
  .MiddlePart {
    cursor: pointer;
    background: mixins.$white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    height: 400px;
    border-radius: 40px;
    width: calc(33% - 20px);
    @include mixins.MQ(mobile) {
      // width: calc(100% - 20px);
      transform: scale(0.95) translate(0px, -20px);
    }
    margin: 10px;
    position: relative;
    .Back {
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      z-index: 1;
      height: 200px;
      width: 100%;
    }
    .photo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: mixins.$black;
      position: absolute;
      left: calc(50% - 100px);
      top: calc(50% - 145px);
      height: 200px;
      width: 200px;
      z-index: 2;
      border-radius: 52%;
      border: 10px solid mixins.$white;
    }
    .desc {
      position: absolute;
      z-index: 2;
      padding: 15px 30px;
      font-size: 14px;
      line-height: 16px;
      top: calc(50% + 38px);
      max-height: 107px;
      font-weight: 600;
      overflow: hidden;
      :global(h1) {
        font-size: 16px !important;
      }
    }
    .title {
      position: absolute;
      z-index: 3;
      padding: 8px;
      font-size: 14px;
      font-weight: 999;
      top: 0;
      color: mixins.$Nwhite;
      text-shadow: 0 0 8px #000;
      background: mixins.$color-5;
    }
    .more {
      position: absolute;
      bottom: 12px;
      right: 30px;
      z-index: 10;
      color: #fff;
      background: mixins.$color-4;
      font-size: 14px;
      text-shadow: 0 0 10px #000;
      font-weight: 100;
      padding: 8px;
      border-radius: 6px;
    }
  }
  .see_full_list {
    background: mixins.$color-4;
    border-radius: 30px;
    padding: 10px 20px;
    color: white;
    margin: 40px;
    cursor: pointer;
    text-shadow: 3px 3px 5px rgb(0 0 0 / 46%);
    box-shadow: 0 0 15px 0px rgb(0 0 0 / 25%);
  }
</style>
