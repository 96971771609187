<script>
  import { Link } from "yrv";
  import Timer from "./countdown.svelte";
  import Menu from "./menu.svelte";
  import ChangeLang from "./langs.svelte";
  import { onMount } from "svelte";
  import { Data } from "@stores/app_store.js";
  let version = $Data.Ver;
  let header, headerTrigger, logo;
  let social = $Data.social;
  // console.log(version);
  var observer = new IntersectionObserver(
    ([entry]) => {
      if (!header) return;
      if (entry.intersectionRatio !== 0) {
        logo.src =
          "https://www.gffootballplayers.com/Logos/logo.png?v=" + version;
        header.classList.remove("sticked");
      } else {
        logo.src =
          "https://www.gffootballplayers.com/Logos/hor_logo.png?v=" + version;
        header.classList.add("sticked");
      }
    },
    {
      threshold: [0, 1],
    }
  );
  onMount(() => {
    observer.observe(headerTrigger);
  });
</script>

<div class="Trigger" bind:this={headerTrigger} />
<div class="header sticked" bind:this={header}>
  <div class="line1">
    <div class="LeftPart">
      <div class="workHard" style="font-family:'Comfortaa', sans-serif">
        #Work Hard Together
      </div>
      <!-- <Timer /> -->
      <Link href="/">
        <img
          src="https://www.gffootballplayers.com/images/logo.png?v={version}"
          alt="logo"
          class="logo"
          bind:this={logo}
        />
      </Link>
    </div>
    <div class="RightPart">
      {#if social.facebook.active}
        <a href={social.facebook.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-facebook-f" />
          </div>
        </a>
      {/if}
      {#if social.twitter.active}
        <a href={social.twitter.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-twitter" />
          </div>
        </a>
      {/if}
      {#if social.youtube.active}
        <a href={social.youtube.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-youtube" />
          </div>
        </a>
      {/if}
      {#if social.linkedin.active}
        <a href={social.linkedin.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-linkedin-in" />
          </div>
        </a>
      {/if}
      {#if social.instagram.active}
        <a href={social.instagram.url} target="_blank">
          <div class="socialBox">
            <i class="fab fa-instagram" />
          </div>
        </a>
      {/if}
      {#if social.tm.active}
        <a href={social.tm.url} target="_blank">
          <div class="socialBox">
            <img
              src="https://www.gffootballplayers.com/images/social/tm.png?v={$Data.Ver}"
              alt="transfermarkt"
              style="height: 25px;"
            />
          </div>
        </a>
      {/if}
    </div>
  </div>
  <div class="line2">
    <Menu />
    <ChangeLang />
  </div>
</div>

<style lang="scss">
  @use "../../styles/partial/mixins";
  .Trigger {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100px;
  }

  .header {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 120px;
    background: rgb(38 94 178 / 50%);
    backdrop-filter: blur(5px);
  }
  .header.sticked {
    top: -60px;
    position: fixed;
    transition: top 0.3s ease-out;
    .line2 {
      background: mixins.$color-3;
      border-bottom: 3px solid mixins.$Nwhite;
    }
    .logo {
      position: relative;
      top: 60px;
      left: 15px;
      width: 115px;
    }
  }
  .line1 {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 60px;
    padding-left: mixins.$paddingL;
    padding-right: mixins.$paddingL;
    @include mixins.MQ(mobile) {
      padding-left: mixins.$paddingS;
      padding-right: mixins.$paddingS;
    }
    @include mixins.BP(Ll) {
      padding-left: mixins.$paddingM;
      padding-right: mixins.$paddingM;
    }
    @include mixins.MQ(wide) {
      padding-left: mixins.$padding;
      padding-right: mixins.$padding;
    }
  }
  .line2 {
    background: linear-gradient(90deg, #002c5988 0%, #004c9ea2 100%);
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: mixins.$paddingL;
    padding-right: mixins.$paddingL;
    @include mixins.MQ(mobile) {
      padding-left: mixins.$paddingS;
      padding-right: mixins.$paddingS;
    }
    @include mixins.BP(Ll) {
      padding-left: mixins.$paddingM;
      padding-right: mixins.$paddingM;
    }
    @include mixins.MQ(wide) {
      padding-left: mixins.$padding;
      padding-right: mixins.$padding;
    }
  }
  .LeftPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(50% + 45px);
    height: 100%;
    float: left;
  }

  .RightPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    float: right;
    @include mixins.MQ(mobile) {
      position: absolute;
      right: 5px;
      top: -29px;
    }
    .socialBox {
      width: 47px;
      height: 50px;
      @include mixins.BP(Ml) {
        width: 32px;
      }
      @include mixins.BP(Mm) {
        width: 25px;
      }
      @include mixins.BP(Ms) {
        width: 20px;
      }
      @include mixins.BP(T) {
        width: 8vw;
      }
      transition: background 1s;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background: mixins.$white;
        transition: color 1s;
        i.fab {
          color: mixins.$color-2;
          font-size: 18px;
        }
      }

      i.fab {
        color: mixins.$white;
        font-size: 18px;
      }
    }
  }

  .workHard {
    color: mixins.$white;
    font-size: 16px;
    font-weight: 999;
    text-shadow: 0 0 19px #000;
    @include mixins.BP(Ms) {
      font-size: 11.6px;
    }
    @include mixins.BP(Mm) {
      font-size: 14px;
    }
    @include mixins.BP(Ml) {
      font-size: 15px;
    }
    @include mixins.MQ(mobile) {
      position: relative;
      left: 4px;
      font-size: 3.2vw;
    }
  }
  .logo {
    position: relative;
    top: 34px;
    width: 90px;
  }
</style>
