<script>
  import Header from "@components/header/header.svelte";
  import Container from "@components/container/container.svelte";
  import Body from "./components/body.svelte"; 
  import { Data } from "@stores/app_store.js";
</script>

<svelte:head>
  <title>{$Data.Seo.Title}</title>
  <html lang="el" />
</svelte:head>
<Header /> 
<Container {Body} />
