<script>
  import { Link, router } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let trainers = $Data.trainers;
  let maxheight = 0;
</script>

<div class="fullWidth inner">
  {#each trainers as item, i}
    {#if item._id == $router.params.slug}
      <div class="item">
        <div class="container">
          <div class="box popup" id="box_{i}">
            <div
              class="box_img"
              style="background:url('https://www.gffootballplayers.com/{item.Big_Image
                ? item.Big_Image.path
                : ''}')"
            >
              <img
                src="https://www.gffootballplayers.com/{item.Preview_Image
                  ? item.Preview_Image.path
                  : ''}"
                width="40%"
                align="left"
                alt="image"
                class="Pimage"
              />
              <div class="box_head">{item["Title" + get_lang()]}</div>
            </div>

            <div class="box_desc">
              {@html item["Description" + get_lang()]}
            </div>
          </div>
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .inner {
    width: 100%;
    .container {
      width: 100%;
      .box {
        background: mixins.$white;
        overflow: hidden;
        &:hover {
          box-shadow: 0 0 1px 5px rgba(61, 158, 215, 0.63);
        }
        .box_img {
          position: relative;
          width: 100%;
          height: 450px;
          background-position: top;
          background-size: 100% 100%;
          @include mixins.MQ(mobile) {
            background-size: cover;
          }
          float: left;
        }
        img.Pimage {
          width: 300px;
          border-radius: 500px;
          height: 300px;
          position: absolute;
          border: 10px solid white;
          object-fit: cover;
          top: 50px;
          left: calc(50% - 150px);
        }
        .box_head {
          width: 100%;
          font-weight: 900;
          position: absolute;
          bottom: 0;
          color: mixins.$Nwhite;
          background: rgba(0, 0, 0, 0.5);
          margin: 0px !important;
          padding: 20px;
          text-align: center;
          font-size: 38px !important;
        }
        .box_time {
          width: 50px;
          height: 100px;
          font-weight: 900;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 9;
          text-align: center;
          color: mixins.$Nwhite;
          background: mixins.$color-4;
          margin: 0px !important;
          padding: 27px 5px;
          font-size: 15px !important;
        }
        .box_desc {
          font-size: 16px;
          overflow: hidden;
          float: left;
          width: 90%;
          overflow-wrap: break-word;
          margin: 60px;
          @include mixins.MQ(mobile) {
            font-size: 14px;
            width: 90%;
            margin: 16px;
          }
          img {
            margin: 25px;
            @include mixins.MQ(mobile) {
              margin: 22px 10px 0px 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
