<script>
  import { Data, get_lang,set_maxItemsPerColumn } from "@stores/app_store.js";
  import { navigateTo } from "yrv";
  import Video from "./video.svelte";
  let langs = $Data.langs[0];
  const opentab = (a) => {
    document.querySelector("#_" + a).classList.toggle("open");
  };
  import { onMount } from "svelte";
  onMount(() => {
    document.querySelector("#Main").scrollTop = 0;
  });
  set_maxItemsPerColumn(3);
</script>

<Video />

<div class="fullWidth section2">
  <div
    class="Title"
    on:click={() => {
      navigateTo("/contact", { replace: true });
    }}
  >
    {@html langs.RegisterNow?.button
      ? langs.RegisterNow.button
      : "ΚΑΝΕ ΕΓΓΡΑΦΗ ΤΩΡΑ"}
  </div>
  <div class="fullWidth appearleft">
    {@html langs.questionsSection.description}
  </div>
  <div class="fullWidth appearright">
    <blockquote>
      {#each langs.QerotisisAll as item, i}
        <p>
          <em><strong> {@html item.value.title}</strong> </em>
          {@html item.value.description}
        </p>
      {/each}
    </blockquote>
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .fullWidth.appearleft {
    float: left;
    width: 100%;
    @include mixins.MQ(pc) {
      // box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.13);
      border-radius: 10px;
      overflow: hidden;
      background: white;
    }
    @include mixins.MQ(mobile) {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 49%);
      gap: 10px;
    }
    margin-top: 20px;
    :global(p) {
      background-color: white;
      padding: 10px;
      border-radius: 10px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        font-size: 14px;
        line-height: 22px;
        font-weight: bold;
        font-family: "Roboto" !important;
        @include mixins.MQ(pc) {
          font-size: 20px;
          line-height: 24px;
          font-weight: 900;
          color: #1034a6;
          padding: 10px;
          background: whitesmoke;
          font-family: Comfortaa, sans-serif !important;
          margin: 10px;
        }
      }
      &:nth-child(3) {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        font-family: "Roboto" !important;
        @include mixins.MQ(pc) {
          font-size: 20px;
          line-height: 28px;
          font-weight: 700;
          color: #1034a6;
          padding: 10px;
          background: #f5f5f5;
          font-family: Comfortaa, sans-serif !important;
          margin: 10px;
          place-content: flex-start;
        }
      }
      &:last-child {
        grid-column: 1 / -1;
      }
    }

    :global(*) {
      // font-family: Comfortaa, sans-serif !important;
      color: black;
      // font-weight: bold;
      line-height: 26px;
      font-weight: 300;
      @include mixins.MQ(mobile) {
        line-height: 14px;
        font-size: 12px;
      }
    }
  }

  .fullWidth.appearright {
    float: right;
    width: 100%;
    @include mixins.MQ(mobile) {
      width: 100%;
    }
    :global(*) {
      // font-family: Comfortaa, sans-serif !important;
      font-weight: 500;
      line-height: 19px;
      font-size: 16px;
      @include mixins.MQ(mobile) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .fullWidth.section2 {
    padding: 30px;
    bottom: 110px;
    @include mixins.MQ(mobile) {
      padding: 15px;
      bottom: 35px;
    }
    overflow-wrap: break-word;
    max-width: 1350px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .Title {
    padding: 10px 20px;
    font-size: 19px;
    font-weight: 999;
    color: #f5f5f5;
    background: linear-gradient(90deg, #2365b2, #461bbb);
    width: 320px;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    @include mixins.BP(Ms) {
      width: 90%;
      padding: 10px 0 10px 40px;
      position: relative;
      left: -20px;
    }
  }
</style>
