<script>
  import { router, navigateTo } from "yrv";
  import { get_lang, getAverageRGB } from "@stores/app_store.js";
  import { onMount } from "svelte";
  import SVG from "@services/SVG.svelte";
  export let langs, players;
  let background,
    middle,
    left,
    stats,
    sliderCurrent = 0,
    Initialized = false;
  let listOfPositions = null;
  $: {
    if (Initialized) {
      $router;
      changePlayer();
      if (players[sliderCurrent])
        listOfPositions = players[sliderCurrent].Position;
    }
  }
  onMount(() => {
    Initialized = true;
  });
  const changePlayer = () => {
    if ($router.params.slug) {
      sliderCurrent = players.findIndex((x) => x._id === $router.params.slug);
      nextprev();
    } else {
      // console.log($router);
      if (players[0]) {
        navigateTo("/player/" + players[0]._id, {
          replace: true,
          queryParams: $router.query,
        });
      }
    }
    // console.log(sliderCurrent);
  };

  const nextprev = (a) => {
    stats.classList.remove("popup");
    middle.classList.remove("zoom");
    setTimeout(() => {
      stats.classList.add("popup");
      middle.classList.add("zoom");
    }, 10);

    left.style.backgroundImage =
      "url('https://www.gffootballplayers.com/" +
      (players[sliderCurrent]?.IconImage.path
        ? players[sliderCurrent].IconImage.path
        : "") +
      "')";
    middle.style.backgroundImage =
      "url('https://www.gffootballplayers.com/" +
      players[sliderCurrent].ProfileImage?.path +
      "')";
    setTimeout(() => {
      let color = getAverageRGB(
        "https://www.gffootballplayers.com/" +
          players[sliderCurrent].IconImage?.path
      );
      // console.log(color);
      background.style.backgroundColor = color;
    }, 100);
  };
</script>

<svelte:head>
  <title>{players[sliderCurrent]["Onoma" + get_lang()]}</title>
</svelte:head>

{#if players[sliderCurrent]}
  <div class="Background" bind:this={background}>
    <div class="overbackground" />
    <div class="overbackground sec" />
    <div class="Left popupslow" bind:this={left} />
    <div class="Middle popupfast" bind:this={middle} />
    <div class="name popup">{players[sliderCurrent]["Onoma" + get_lang()]}</div>
    <div class="stats popup" bind:this={stats}>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-map-marker" />
          {langs.Pthesi}
        </div>

        <div class="bottom">
          <div class="mini_pos">
            {#each players[sliderCurrent].Position as item, i}
              {item["Fullname" + get_lang()] !== ""
                ? item["Fullname" + get_lang()]
                : "-"}
              {#if i % 1 == 0}/{/if}
            {/each}
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-globe-americas" />
          {langs.Pethnikotita}
        </div>
        <div class="bottom">
          <img
            src="https://www.gffootballplayers.com/{players[sliderCurrent]
              .Ethnikotita.Flag.path}"
            width="30px"
            height="30px"
            alt="flag"
            style="margin:3px;object-fit: contain;"
          />
          {players[sliderCurrent].Ethnikotita["Fullname" + get_lang()] !== ""
            ? players[sliderCurrent].Ethnikotita["Fullname" + get_lang()]
            : "-"}
        </div>
      </div>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-child" />
          {langs.PHmeromiaGenisis}
        </div>
        <div class="bottom">
          {players[sliderCurrent]["HmerominiaGenisis"] !== ""
            ? players[sliderCurrent]["HmerominiaGenisis"]
            : "-"}
        </div>
      </div>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-arrows-alt-v" />
          {langs.Pypsos}
        </div>
        <div class="bottom">
          {players[sliderCurrent]["Ypsos"] !== ""
            ? players[sliderCurrent]["Ypsos"]
            : "-"}
        </div>
      </div>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-weight" />
          {langs.Pbaros}
        </div>
        <div class="bottom">
          {players[sliderCurrent]["Baros"] !== ""
            ? players[sliderCurrent]["Baros"]
            : "-"}
        </div>
      </div>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-shoe-prints" />
          {langs.Ppodi}
        </div>
        <div class="bottom">
          {players[sliderCurrent]["Podi" + get_lang()] !== ""
            ? players[sliderCurrent]["Podi" + get_lang()]
            : "-"}
        </div>
      </div>
      <div class="wrapper">
        <div class="top">
          <i class="fas fa-user-friends" />
          {langs.Pomada}
        </div>
        <div class="bottom">
          {players[sliderCurrent]["Omada" + get_lang()] !== ""
            ? players[sliderCurrent]["Omada" + get_lang()]
            : "-"}
        </div>
      </div>
    </div>
    <div class="overlay" />
    <div class="positions">
      {#each players[sliderCurrent].Position as item, i}
        <div
          class="pointer"
          class:active={item.ID == "GK"}
          id="GK"
          style=" top:30px;"
        >
          GK
        </div>
        <div
          class="pointer"
          class:active={item.ID == "LWB"}
          id="LWB"
          style="top: 34%; left: 76%;"
        >
          LWB
        </div>
        <div
          class="pointer"
          class:active={item.ID == "LB"}
          id="LB"
          style=" top: 23%; left: 78%;"
        >
          LB
        </div>
        <div
          class="pointer"
          class:active={item.ID == "RWB"}
          id="RWB"
          style=" top: 34%; left: 18%;"
        >
          RWB
        </div>
        <div
          class="pointer"
          class:active={item.ID == "RB"}
          id="RB"
          style=" top: 23%; left: 19%;"
        >
          RB
        </div>
        <div
          class="pointer"
          class:active={item.ID == "CB"}
          id="CB"
          style=" top: 25%; left: 47%;;"
        >
          CB
        </div>
        <div
          class="pointer"
          class:active={item.ID == "SW"}
          id="SW"
          style=" top: 15%; left: 48%;"
        >
          SW
        </div>
        <div
          class="pointer"
          class:active={item.ID == "CDM"}
          id="CDM"
          style=" top: 34%; left: 46.5%;"
        >
          CDM
        </div>
        <div
          class="pointer"
          class:active={item.ID == "LM"}
          id="LM"
          style=" top: 47%; left: 74%;"
        >
          LM
        </div>
        <div
          class="pointer"
          class:active={item.ID == "RM"}
          id="RM"
          style=" top: 47%; left: 16%;"
        >
          RM
        </div>
        <div
          class="pointer"
          class:active={item.ID == "CM"}
          id="CM"
          style="top: 47%; left: 46%;"
        >
          CM
        </div>
        <div
          class="pointer"
          class:active={item.ID == "LW"}
          id="LW"
          style=" top: 71%; left: 75%;"
        >
          LW
        </div>
        <div
          class="pointer"
          class:active={item.ID == "LF"}
          id="LF"
          style=" top: 82%; left: 64%;"
        >
          LF
        </div>
        <div
          class="pointer"
          class:active={item.ID == "RW"}
          id="RW"
          style="top: 71%; left: 14%;"
        >
          RW
        </div>
        <div
          class="pointer"
          class:active={item.ID == "RF"}
          id="RF"
          style=" top: 82%; left: 24%;"
        >
          RF
        </div>
        <div
          class="pointer"
          class:active={item.ID == "AM"}
          id="AM"
          style=" top: 60%; left: 45.4%;"
        >
          AM
        </div>
        <div
          class="pointer"
          class:active={item.ID == "CF"}
          id="CF"
          style="top: 83%; left: 44%;"
        >
          CF
        </div>
        <div
          class="pointer"
          class:active={item.ID == "ST"}
          id="ST"
          style="top: 70%; left: 45%;"
        >
          ST
        </div>
      {/each}
    </div>
  </div>
{/if}

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .Background {
    width: 100%;
    height: 100%;
    background-color: rgb(45, 20, 156);
    transition: background-color 1s;
    display: flex;
  }
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 58%;
    z-index: 9;
    background: rgb(54, 49, 145);
    background: linear-gradient(
      0deg,
      #2b3144,
      #2b3144 5%,
      rgba(45, 64, 129, 0.95) 40%,
      rgba(35, 85, 200, 0.6) 50%,
      rgba(0, 212, 255, 0)
    );
    @include mixins.MQ(mobile) {
      height: 72%;
      background: linear-gradient(
        0deg,
        #2b3144,
        #2b3144 5%,
        rgba(45, 64, 129, 1) 40%,
        rgba(35, 85, 200, 0.95) 57%,
        rgba(0, 212, 255, 0)
      );
    }
  }
  .positions {
    position: absolute;
    bottom: 150px;
    right: 25px;
    width: 350px;
    height: 450px;
    z-index: 9;
    background: url(https://www.gffootballplayers.com/images/stadium.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    @include mixins.MQ(mobile) {
      transform: rotate(-90deg) skew(2.4deg, 0deg);
      top: 450px;
      right: calc(50% - 122px);
      background-size: 100% 100%;
      @include mixins.BP(Ms) {
        bottom: -125px;
      }
      @include mixins.BP(Mm) {
        height: 100vw;
        bottom: -78px;
      }
      @include mixins.BP(Ms) {
        transform: rotate(-90deg) skew(2.8deg, 0deg) scale(0.7);
      }
      @include mixins.BP(mmm) {
        transform: rotate(-90deg) skew(2.8deg, 0deg) scale(0.95);
      }
      width: 250px;
    }
    mix-blend-mode: lighten;
    transform: rotate(180deg) skew(3deg, 0deg) scale(1.05) translateY(-30px)
      translateX(20px);
    .pointer {
      transform: rotate(180deg);
      position: absolute;
      width: 25px;
      height: 25px;
      background: rgb(255, 255, 255);
      border-radius: 50%;
      top: 30px;
      left: calc(50% - 2px);
      opacity: 0;
      font-size: 11px !important;
      font-weight: bold;
      text-align: center;
      color: #000;
      padding: 6px 0;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      @include mixins.MQ(mobile) {
        transform: rotate(93deg) skew(2.4deg, 0deg);
      }
      &.active {
        opacity: 1;
        animation: show 1s ease-in-out infinite;
      }
    }
  }
  @keyframes show {
    0% {
      box-shadow: 0 0 2px 1px rgb(7, 142, 233);
    }
    100% {
      box-shadow: 0 0 2px 20px rgb(7, 97, 231, 0);
    }
  }

  .Left {
    position: relative;
    top: 100px;
    right: 20px;
    z-index: 3;
    width: 60%;
    opacity: 1;
    height: 70%;
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    filter: contrast(120%) drop-shadow(-1px -4px 0px cyan)
      drop-shadow(4px 0px 0px white) drop-shadow(-4px 15px 30px blue);
    @include mixins.MQ(mobile) {
      top: 30px;
      z-index: 3;
      left: -30px;
      height: 100vh;
      width: 100vw;
      background-size: 95%;
      @include mixins.BP(T) {
        background-size: 80vw;
        left: -20vw;
        height: 600px;
      }
    }
  }
  .Middle {
    top: 30px;
    z-index: 4;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 20%;
    background-size: 70%;
    background-position: 30px 20px;
    background-repeat: no-repeat;
    filter: contrast(120%);
    @include mixins.MQ(mobile) {
      top: -80px;
      z-index: 4;
      background-position: left;
      left: 0;
      width: 100vw;
      background-size: 135%;
      @include mixins.BP(T) {
        background-size: 93vw;
        left: 30%;
        top: 10px;
      }
      @include mixins.BP(MT) {
        background-size: 120%;
        left: 70px;
        top: -20px;
      }
    }
  }
  .overbackground {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: url(https://www.gffootballplayers.com/images/PlayerBG.png);
    opacity: 0.2;
    &.sec {
      z-index: 2;
      background-image: url(https://gffootballplayers.com/images/background_player_lines.png);
      opacity: 0.5;
      height: 100%;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom;
      max-width: 840px;
      @include mixins.MQ(mobile) {
        height: 50%;
        width: 100%;
        background-size: 140% 90%;
      }
    }
    background-position: 190px 0px;
    @include mixins.MQ(mobile) {
      background-position: -250px -100px;
    }
  }
  .name {
    position: absolute;
    top: 30px;
    font-size: 45px;
    z-index: 10;
    color: white;
    text-align: center;
    font-weight: 700;
    width: 100vw;
    @include mixins.MQ(mobile) {
      font-size: 8vw;
      // text-shadow: 0 0 10px black;
      @include mixins.BP(T) {
        font-size: 5vw;
      }
      text-align: right;
      font-size: 30px;
      right: 22px;
      top: 310px;
      max-width: 320px;
      text-shadow: 0 0 16px BLACK;
    }
  }
  .stats {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 590px;
    z-index: 10;
    color: white;
    font-size: 18px;
    width: 100%;
    @media (max-width: 1144px) and (min-width: 769px) {
      transform: scale(0.8);
      bottom: 40px;
    }
    @include mixins.MQ(mobile) {
      left: -10px;
      font-size: 13px;
      flex-wrap: wrap;
      top: 390px;
      height: 160px;
    }
    text-align: center;
    font-weight: 100;
    display: flex;
    .wrapper {
      padding: 0 20px;
      border-right: 1px solid white;
      line-height: 23px;
      text-shadow: 0 0 8px black;
      @include mixins.MQ(mobile) {
        width: 33.1%;
        margin: 10px 0;
        line-height: 16px;
        padding: 0;
        &:last-child {
          width: 100%;
        }
        &:nth-child(3),
        &:nth-child(6) {
          border-right: 0px solid white;
        }
      }
      &:last-child {
        border-right: 0px solid white;
      }

      .top {
        font-size: 18px;
        @include mixins.MQ(mobile) {
          font-size: 12px;
        }
        font-weight: 990;
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include mixins.MQ(mobile) {
          font-size: 11px;
          max-width: 150px;
          margin: 0 auto;
        }
        .mini_pos {
          @include mixins.MQ(mobile) {
            position: relative;
            top: 4px;
            height: 40px;
            line-height: 11px;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 80%;
          }
        }
      }
    }
  }
</style>
