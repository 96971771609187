<script>
  import { navigateTo } from "yrv";
  import Video from "./video.svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let idrites = $Data.Idrites;
  import { onMount } from "svelte";
  let TopPart, header, body;

  var observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.intersectionRatio !== 0) {
        header.classList.add("popup");
        body.classList.add("popupslow");
      }
    },
    {
      threshold: [0, 1],
    }
  );
  onMount(() => {
    observer.observe(TopPart);
    document.querySelector("#Main").scrollTop = 0;
  });
</script>

<svelte:head>
  <title>GFF Founders</title>
</svelte:head>

<Video />

<div class="fullWidth inner">
  <div class="video_over_title popup">
    <div class="video_over_title_">
      <h1>
        {@html langs.Midrites}
      </h1>
    </div>
  </div>
  <div class="wrapper_">
    <div class="TopPart" bind:this={TopPart}>
      <div class="body" bind:this={body}>
        {@html langs.YDescription}
      </div>
    </div>
    {#each idrites as item, i}
      {#if i % 3 == 0}
        <div
          class="LeftPart appearleft"
          on:click={() => {
            navigateTo("/founder/" + item._id);
          }}
        >
          <div
            class="Back"
            style="background-image:url(https://www.gffootballplayers.com/{item.Big_Image
              ? item.Big_Image.path
              : ''})"
          />
          <div
            class="photo"
            style="background-image:url(https://www.gffootballplayers.com/{item.Preview_Image
              ? item.Preview_Image.path
              : ''})"
          />
          <div class="word">
            <div class="title">{item["Title" + get_lang()]}</div>
            <div class="desc">
              {@html item["ShortDescription" + get_lang()]}
            </div>
            <div class="more">{langs.Ymorebutton}</div>
          </div>
        </div>
      {:else if i % 2 == 0}
        <div
          class="MiddlePart appearleft"
          on:click={() => {
            navigateTo("/founder/" + item._id);
          }}
        >
          <div
            class="Back"
            style="background-image:url(https://www.gffootballplayers.com/{item.Big_Image
              ? item.Big_Image.path
              : ''})"
          />
          <div
            class="photo"
            style="background-image:url(https://www.gffootballplayers.com/{item.Preview_Image
              ? item.Preview_Image.path
              : ''})"
          />
          <div class="word">
            <div class="title">{item["Title" + get_lang()]}</div>
            <div class="desc">
              {@html item["ShortDescription" + get_lang()]}
            </div>
            <div class="more">{langs.Ymorebutton}</div>
          </div>
        </div>
      {:else}
        <div
          class="RightPart appearright"
          on:click={() => {
            navigateTo("/founder/" + item._id);
          }}
        >
          <div
            class="Back"
            style="background-image:url('https://www.gffootballplayers.com/{item.Big_Image
              ? item.Big_Image.path
              : ''}')"
          />
          <div
            class="photo"
            style="background-image:url('https://www.gffootballplayers.com/{item.Preview_Image
              ? item.Preview_Image.path
              : ''}')"
          />
          <div class="word">
            <div class="title">{item["Title" + get_lang()]}</div>
            <div class="desc">
              {@html item["ShortDescription" + get_lang()]}
            </div>
            <div class="more">{langs.Ymorebutton}</div>
          </div>
        </div>
      {/if}
    {/each}
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .video_over_title {
    .video_over_title_ {
      height: 60px;
      position: absolute;
      top: -98px;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      max-width: 1350px;
      z-index: 9;
      @include mixins.MQ(mobile) {
        height: 80px;
        z-index: 9;
        top: -58px;
      }
      h1 {
        font-size: 50px;
        background: whitesmoke;
        color: #2a457d;
        padding: 6px;
        border-radius: 80px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        @include mixins.MQ(mobile) {
          font-size: 26px;
        }
      }
    }
  }

  .inner {
    background: #f5f5f5;
    width: 100%;
    position: relative;
    z-index: 9;
    bottom: 20px;
    @include mixins.MQ(mobile) {
      bottom: 70px;
    }
    min-height: 230px;
    .wrapper_ {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      justify-content: center;
      padding-bottom: 30px;
      position: relative;
      bottom: 0;
      max-width: 1350px;
      left: 50%;
      transform: translateX(-50%);
      background: whitesmoke;
      .info {
        text-align: center;
        width: 90%;
      }
    }
  }

  .MiddlePart {
    border-bottom-left-radius: 10px !important;
  }
  .LeftPart {
    border-bottom-right-radius: 10px !important;
  }
  .RightPart {
    border-radius: 0 !important;
  }

  .RightPart,
  .LeftPart,
  .MiddlePart {
    cursor: pointer;
    background: mixins.$white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.17);
    height: 400px;
    // border-radius: 40px;
    width: calc(33% - 20px);
    @include mixins.MQ(mobile) {
      // width: calc(54% - 20px);
      width: 100%;
      margin: 0;
      padding: 0;
      border-radius: 0 !important;
      border: 3px solid #efefef;
    }
    margin: 10px;
    position: relative;
    .Back {
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      @include mixins.MQ(mobile) {
        background-size: contain;
      }
      position: absolute;
      z-index: 1;
      height: 230px;
      width: 100%;
    }
    .photo {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-color: mixins.$black;
      position: absolute;
      left: calc(50% - 100px);
      top: calc(50% - 145px);
      height: 200px;
      width: 200px;
      @include mixins.MQ(mobile) {
        left: calc(50% - 85px);
        top: calc(50% - 125px);
        height: 170px;
        width: 170px;
        border: 6px solid #fff;
      }
      z-index: 2;
      border-radius: 52%;
      border: 10px solid mixins.$white;
    }
    .desc {
      position: absolute;
      z-index: 2;
      padding: 15px 30px;
      font-size: 14px;
      line-height: 16px;
      top: calc(50% + 38px);
      max-height: 107px;
      font-weight: 600;
      overflow: hidden;
      :global(h1) {
        font-size: 16px !important;
      }
      @include mixins.MQ(mobile) {
        position: absolute;
        z-index: 2;
        padding: 2px 10px;
        font-size: 14px;
        line-height: 16px;
        top: calc(50% + 38px);
        max-height: 97px;
        font-weight: 600;
        overflow: hidden;
      }
    }
    .title {
      position: absolute;
      z-index: 3;
      padding: 8px;
      font-size: 14px;
      font-weight: 999;
      top: 0px;
      color: mixins.$Nwhite;
      text-shadow: 0 0 8px #000;
      background: mixins.$color-5;
      @include mixins.MQ(mobile) {
        display: flex;
        width: 100%;
        height: 60px;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        padding: 0;
        padding-right: 12px;
        padding-left: 12px;
        font-size: 12px;
      }
    }
    .more {
      position: absolute;
      bottom: 12px;
      right: 30px;
      z-index: 10;
      color: #fff;
      background: mixins.$color-4;
      font-size: 14px;
      text-shadow: 0 0 10px #000;
      font-weight: 100;
      padding: 8px;
      border-radius: 6px;
      @include mixins.MQ(mobile) {
        right: unset;
        left: 10px;
      }
    }
  }
</style>
