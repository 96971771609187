<script>
  import Playerslider from "./playerslider.svelte";
  import Federations from "./federations.svelte";
  import Whoweare from "./whoweare.svelte";
  import About from "./about.svelte";
  import Secvices from "./services.svelte";
  import Questions from "./questions.svelte";
  import Contact from "./contact.svelte";
  import TopNews from "@views/news/components/topnews.svelte";
  import { onMount } from "svelte";
  import Video from "@views/index/components/video.svelte";

  onMount(() => {
    document.querySelector("#Main").scrollTop = 0;
  });
</script>

<div class="fullWidth sectionvideo">
  <Video />
</div>

<div class="fullWidth section0">
  <About />
</div>
<div class="fullWidth section2">
  <Federations />
</div>

<!-- <div class="fullWidth section0">
  <Secvices />
</div> -->

<div class="fullWidth section4">
  <Whoweare />
</div>

<div class="fullWidth section1">
  <Playerslider />
</div>

<div class="fullWidth section5">
  <Questions />
</div>
<div class="fullWidth section3">
  <TopNews />
</div>
<div class="fullWidth section6">
  <Contact />
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .fullWidth.sectionvideo {
    background-color: mixins.$Nwhite;
    height: 100vh;
    max-height: 90vh;
    @include mixins.MQ(mobile) {
      max-height: 80vh;
    }
    position: relative;
  }

  .fullWidth.section0 {
    background-color: mixins.$Nwhite;
    height: 650px;
    @include mixins.MQ(mobile) {
      height: 595px;
    }
    position: relative;
  }

  .fullWidth.section6 {
    background-color: mixins.$Nwhite;
    height: 950px;
    @include mixins.MQ(mobile) {
      height: 1100px;
    }
    position: relative;
  }

  .fullWidth.section5 {
    background-color: mixins.$Nwhite;
    height: 250px;
    @include mixins.MQ(mobile) {
      height: 270px;
    }
  }

  .fullWidth.section1 {
    height: 620px;
    .Background {
      overflow: hidden;
    }
    @include mixins.MQ(mobile) {
      height: 520px;
    }
    position: relative;
  }
  .fullWidth.section2 {
    height: 170px;
    background-color: #22396e;
  }
  .fullWidth.section3 {
    height: 670px;
    background: #e3ecec;
    padding: 20px 11%;
    @include mixins.MQ(mobile) {
      height: 470px;
      background: #f5f5f5;
      padding: 0;
      overflow: hidden;
    }
  }
</style>
