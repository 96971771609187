<script>
  import { Data, get_lang } from "@stores/app_store.js";
  import { onMount } from "svelte";
  let flag;
  onMount(() => {
    switch (get_lang()) {
      case "EL":
        flag.src = "https://www.gffootballplayers.com/images/flags/greece.png";
        break;
      case "EN":
        flag.src = "https://www.gffootballplayers.com/images/flags/english.png";
        break;
      case "SP":
        flag.src = "https://www.gffootballplayers.com/images/flags/spain.png";
        break;
    }
  });
</script>

<style lang="scss">
  @use "../../styles/partial/mixins";
  .languagepicker {
    
    display: inline-block;
    padding: 0;
    max-height: 36px;
    max-width: 50px;
    overflow: hidden;
    position: absolute;
    top: 55px;
    right: 5px;
    z-index: 999;
    transition: max-height 0.2s ease-out, max-width 0.2s, background-color 0.5s ease-out;
    @include mixins.BP(L) {
      top: 110px;
    }
    &:hover {
      background-color: #ffffff;
      max-height: 500px;
      max-width: 500px;
    }
    li {
      &:first-child {
        transition: height 0.6s, opacity 0.2s;
        opacity: 1;
        height: 38px;
      }
      display: flex;
      align-items: center;
      padding: 4px;
      cursor: pointer;
      img {
        width: 41px;
        margin: 0;
        margin-right: 5px;
        transform: scale(0.8);
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    &:hover li {
      &:first-child {
        opacity: 0;
        height: 0;
      }
      color: rgb(23, 100, 178) !important;
    }
  }
</style>

<ul class="languagepicker roundborders large">
  <li>
    <img
      src="https://www.gffootballplayers.com/images/flags/greece.png"
      alt="Greek "
      bind:this={flag} />
  </li>
  <li
    on:click={() => {
      Data.ChangeLang('EL');
    }}>
    <img
      src="https://www.gffootballplayers.com/images/flags/greece.png"
      alt="Greek " />
    Greek
  </li>
  <li
    on:click={() => {
      Data.ChangeLang('SP');
    }}>
    <img
      src="https://www.gffootballplayers.com/images/flags/spain.png"
      alt="Spanish" />
    Spainish
  </li>
  <li
    on:click={() => {
      Data.ChangeLang('EN');
    }}>
    <img
      src="https://www.gffootballplayers.com/images/flags/english.png"
      alt="English" />
    English
  </li>

</ul>
