<script>
  import { onMount } from "svelte";
  import { router } from "yrv";
  import { createEventDispatcher } from "svelte";
  export let Player, langs;
  const dispatch = createEventDispatcher();
  const Open = (c) => {
    dispatch("Open", { cf: c });
  };
  // $: $router && recalcHeight();
  const recalcHeight = () => {
    if (container) {
      if (videosTab) {
        container.style.height = (Player.videos.length / 2) * 360 + 405 + "px";
      } else {
        container.style.height = (Player.Photos.length / 3) * 382 + 427 + "px";
      }
    }
  };
  let photosTab = true,
    videosTab = false,
    container;
  onMount(() => {
    // if (Player) {
    //   if (container) {
    //     container.style.height = (Player.Photos.length / 3) * 382 + 427 + "px";
    //   }
    // }
  });
</script>

<div class="container" bind:this={container}>
  <div class="tabs">
    <div
      class="tab"
      class:open={photosTab}
      on:click={() => {
        photosTab = true;
        videosTab = false;
        // container.style.height = (Player.Photos.length / 3) * 382 + 427 + "px";
      }}
    >
      <i class="fas fa-camera" />
      {langs.Pfotografies}
    </div>
    <div
      class="tab"
      class:open={videosTab}
      on:click={() => {
        photosTab = false;
        videosTab = true;
        // container.style.height = (Player.videos.length / 2) * 360 + 405 + "px";
      }}
    >
      <i class="fas fa-film" />
      {langs.Pvideo}
    </div>
  </div>
  <div class="photos" class:active={photosTab}>
    {#if Player && Player.Photos && Player.Photos.length != 0}
      <div class="stats zoom">
        {#each Player.Photos as item, i}
          <div
            class="wrapperP zoom"
            style="background:url('https://www.gffootballplayers.com/{item.path}')"
            on:click={() => {
              Open(i);
            }}
          />
        {/each}
      </div>
    {:else}
      <div class="Empty">
        <i class="fas fa-camera" />
        0 {langs.Pfotografies}
      </div>
    {/if}
  </div>
  <div class="videos" class:active={videosTab}>
    {#if Player && Player.videos && Player.videos.length != 0}
      <div class="stats zoom">
        {#each Player.videos as item, i}
          <div class="wrapperV zoom">
            {@html item.value}
          </div>
        {/each}
      </div>
    {:else}
      <div class="Empty">
        <i class="fas fa-film" />
        0 {langs.Pvideo}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .container {
    width: 100%;
    // min-height: 400px;
    margin-top: 30px;
    position: relative;
    top: 0;
    left: 0;
    .tabs {
      width: 100%;
      height: 45px;
      display: flex;
      position: relative;
      .tab {
        padding: 10px;
        color: mixins.$Nwhite;
        background: mixins.$color-1;
        width: 50%;
        text-align: center;
        border: 2px solid;
        border-left: 0;
        // border-radius: 32px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        transition: margin 0.5s, border-radius 0.5s;
        cursor: pointer;
        &:first-child {
          z-index: 1;
        }
        &:last-child {
          // transform: translateX(-25px);
          &.open {
            // border-top-left-radius: 15px;
          }
        }
        &.open {
          border: 2px solid #57dcff;
          border-left: 0;
          background: linear-gradient(90deg, #1b57b1, #648dff);
          margin-top: -2px;
        }
      }
    }
    .photos,
    .videos {
      width: 100%;
      height: 100%;
      // position: absolute;
      top: 44px;
      left: 0;
      z-index: 9;
      background: mixins.$Nwhite;
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
      overflow: scroll;
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .stats {
    display: flex; 
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    display: flex;
    .wrapperP {
      width: 31%;
      height: 382px;
      margin: 10px;
      overflow: hidden;
      background-size: cover !important;
      background-position: center !important;
      cursor: pointer;
      transition: box-shadow 1s;
      @include mixins.MQ(mobile) {
        height: 222px;
        margin: 0px 0;
        padding: 0 11px;
        width: 33%;
        // height: 270px;
        border:2px solid white;
      }
      &:last-child {
        border-right: 0px solid white;
      }
      &:hover {
        box-shadow: 0 0 8px 7px rgba(0, 0, 0, 0.3);
      }
    }
    .wrapperV {
      padding: 0 10px;
      border-right: 1px solid mixins.$color-2;
      @include mixins.MQ(mobile) {
        margin: 7px 0;
        padding: 0 11px;
      }
      :global(p) {
        margin: 0;
      }
      :global(p iframe) {
        width: 29vw !important;
        @include mixins.MQ(mobile) {
          width: 93vw !important;
        }
      }
      &:last-child {
        border-right: 0px solid white;
      }
    }
  }
  .Empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    font-size: 60px;
    i {
      display: flex;
      justify-content: center;
      font-size: 200px;
      width: 100%;
    }
  }
</style>
