<script>
  import Header from "@components/header/header.svelte";
  import Container from "@components/container/container.svelte";
  import Body from "./components/body.svelte";
  import SlugBody from "./components/slugbody.svelte";
  export let router
</script>
<svelte:head>
	<title>GFF Trainers</title> 
	<html lang="el" />
</svelte:head>
<Header />
{#if !router.params.slug}
  <Container {Body} />
{:else}
  <Container Body={SlugBody} />
{/if}
