<!-- ANCHOR TEMPLATE -->
	{#if icon !== null}
		{@html icon}
	{/if}
<!-- ANCHOR SCRIPTS -->
<script>
	// SECTION PROPS
	export let key;
	export let color="#ffffff";
	export let width="35px";

	// SECTION STATE
	let icon = null
    let extra='style="width: '+width+'; height: auto; fill:'+color+'"';
	// SECTION METHODS
	const findIcon = () => {
		switch (key) {

			case 'Wave1':
				icon = '<svg xmlns="http://www.w3.org/2000/svg" '+extra+'   viewBox="0 0 1440 320"><path fill="#000b76" fill-opacity="1" d="M0,320L40,304C80,288,160,256,240,250.7C320,245,400,267,480,261.3C560,256,640,224,720,224C800,224,880,256,960,261.3C1040,267,1120,245,1200,250.7C1280,256,1360,288,1400,304L1440,320L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>';
				break;
			case 'arrowright':
 
				icon = '<svg '+extra+' viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.489 31.975c-0.271 0-0.549-0.107-0.757-0.316-0.417-0.417-0.417-1.098 0-1.515l14.258-14.264-14.050-14.050c-0.417-0.417-0.417-1.098 0-1.515s1.098-0.417 1.515 0l14.807 14.807c0.417 0.417 0.417 1.098 0 1.515l-15.015 15.022c-0.208 0.208-0.486 0.316-0.757 0.316z"></path> </g></svg>';
				break;
			case 'arrowleft':
			icon = '<svg style="width: '+width+'; height: auto; fill:'+color+'; transform:scaleX(-1)" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M8.489 31.975c-0.271 0-0.549-0.107-0.757-0.316-0.417-0.417-0.417-1.098 0-1.515l14.258-14.264-14.050-14.050c-0.417-0.417-0.417-1.098 0-1.515s1.098-0.417 1.515 0l14.807 14.807c0.417 0.417 0.417 1.098 0 1.515l-15.015 15.022c-0.208 0.208-0.486 0.316-0.757 0.316z"></path> </g></svg>';
				break; 
		 	case 'Filters':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  id="Layer_1" x="0" y="0" version="1.1" viewBox="0 0 16 16" xml:space="preserve"><defs/><path d="M10 3h6v2h-6zM3 7h6V1H3v2H0v2h3zM0 11h6v2H0zM13 9H7v6h6v-2h3v-2h-3z" class="st0"/></svg>';
				break;	
			 case 'ArrowDown':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 512 512"><path fill="none" stroke="'+color+'" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 268l144 144 144-144M256 392V100"/></svg>';
				break;	
			 case 'Refreshicon':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;
			case 'position':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;	
			case 'country':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;	
			case 'birth':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;	
			case 'height':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;	
			case 'weight':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;	
			case 'leg':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;
			case 'team':
				icon = '<svg xmlns="http://www.w3.org/2000/svg"  '+extra+'  viewBox="0 0 342.5 342.5"><path d="M254 22a5 5 0 00-6 2l-22 40c-2 2-1 5 2 6a116 116 0 11-150 35l34 34a5 5 0 009-3L140 6a5 5 0 00-6-5L4 20a5 5 0 00-3 8l38 37a171 171 0 10215-43z"/></svg>';
				break;
			default:
				break;
		}
	};

	// SECTION ON CREATE
	findIcon()
</script>

