<script>
  import Header from "@components/header/header.svelte";
  import Container from "@components/container/container.svelte";
  import Body from "./components/body.svelte";
  import PhotoVideo from "./components/photovideo.svelte";
  import CV from "./components/cv.svelte";
  export let router;
</script>

<Header />
{#if router.params.slug == 'home'}
  <Container {Body} />
{:else if router.params.slug == 'cv'}
  <Container Body={CV} />
{:else if router.params.slug == 'photovideo'}
  <Container Body={PhotoVideo} />
{/if}
