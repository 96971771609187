<script>
  import { Link, router } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let news = $Data.News.slice().sort((a, b) => b._created - a._created);
  let maxheight = 0;

  const getyear = (unixtimestamp) => {
    // Convert timestamp to milliseconds
    var date = new Date(unixtimestamp * 1000);
    // Year
    var year = date.getFullYear();
    return year;
  };

  const getday = (unixtimestamp) => {
    // Convert timestamp to milliseconds
    var date = new Date(unixtimestamp * 1000);
    var day = date.getDate();
    return day;
  };

  const getmonth = (unixtimestamp) => {
    var months_arr = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var date = new Date(unixtimestamp * 1000);
    var month = months_arr[date.getMonth()];
    return month;
  };
</script>

<div class="fullWidth inner">
  {#each news as item, i}
    {#if item._id == $router.params.slug}
      <div class="item">
        <div class="container">
          <div class="box popup" id="box_{i}">
            <div class="box_time">
              {getday(item._modified)}
              <br />
              {getmonth(item._modified)}
              <br />
              {getyear(item._modified)}
            </div>
            {#if item.Tropos_pou_gemizi_to_background && item.Tropos_pou_gemizi_to_background != ""}
              <div
                class="box_img"
                style="background-image:url('https://www.gffootballplayers.com/{item
                  .Big_Image.path}');
                    background-position: 0 -50px; background-size: {item.Tropos_pou_gemizi_to_background}!important;
                    "
              >
                <div class="box_head">{item.Title}</div>
              </div>
            {:else}
              <div
                class="box_img"
                style="background-image:url('https://www.gffootballplayers.com/{item
                  .Big_Image.path}');"
              >
                <div class="box_head">{item.Title}</div>
              </div>
            {/if}
            <div class="box_desc">
              <img
                src="https://www.gffootballplayers.com/{item.Preview_Image
                  .path}"
                width="40%"
                align="left"
                alt="image"
              />
              <br clear="”left”" />
              {@html item.Description}
              {#if item.REFER}
                <a href={item.REFER} target="_blanck">
                  <i class="fas fa-link" />
                  <div class="maxwidth">{item.REFER}</div>
                </a>
              {/if}
            </div>
          </div>
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .inner {
    width: 100%;

    .container {
      width: 100%;

      .box {
        background: mixins.$white;
        overflow: hidden;

        &:hover {
          box-shadow: 0 0 1px 5px rgba(61, 158, 215, 0.63);
        }

        .box_img {
          position: relative;
          width: 100%;
          height: 450px;
          background-position: top;
          background-size: 100% 100%;

          @include mixins.MQ(mobile) {
            background-size: cover;
            background-position: center !important;
          }

          float: left;
        }

        .box_head {
          width: 100%;
          font-weight: 900;
          position: absolute;
          bottom: 0;
          color: mixins.$Nwhite;
          background: rgba(0, 0, 0, 0.5);
          margin: 0px !important;
          padding: 20px;
          font-size: 23px !important;
          text-align: center;
        }

        .box_time {
          width: 50px;
          height: 100px;
          font-weight: 900;
          position: absolute;
          left: 0;
          z-index: 9;
          text-align: center;
          color: mixins.$Nwhite;
          background: mixins.$color-4;
          margin: 0px !important;
          padding: 27px 5px;
          font-size: 15px !important;
          top: 200px;
        }

        .box_desc {
          font-size: 16px;
          overflow: hidden;
          float: left;
          overflow-wrap: break-word;
          margin: 30px;
          margin-top: 15px;
          width: 100%;
          max-width: 1450px;
          margin: 60px;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          background: #ebecf0;
          padding: 20px;
          border-radius: 10px;
          @include mixins.MQ(mobile) {
            font-size: 14px;
            width: 90%;
            margin: 16px;
          }

          img {
            margin: 25px;
            margin-left: 0;

            @include mixins.MQ(mobile) {
              margin: 22px 10px 10px 0px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .fas {
    float: left;
  }

  .maxwidth {
    //max-width: 220px;
    height: 39px;
    overflow: hidden;
    background: #faf9f9;
    /* background: linear-gradient(90deg,#1c45a7,rgba(24,82,189,.2) 100%,hsla(0,0%,100%,0)); */
    border: 3px solid #fff;
    /* color: #fff; 
    border-radius: 22px;
    padding: 5px 20px; */
    font-weight: 999;
  }
</style>
