<script>
  import Slogan from "@components/container/Slogan.svelte";
  import Footer from "@components/footer.svelte";

  export let Body;
  import {
    Data,
    set_maxItemsPerColumn,
    maxItemsPerColumn,
  } from "@stores/app_store.js";
  import { router } from "yrv";
  import { onMount } from "svelte";
  let version = $Data.Ver;
  let _URL = "https://www.gffootballplayers.com/images/bg.jpg?v=" + version;
  set_maxItemsPerColumn(4);
  $: maxItemsPerColumn;
  onMount(() => {
    let listofblockquote = document.querySelectorAll("blockquote p");
    let columnWidthPercentage = 44; // Width of each column in percentage
    let gap = 15; // Gap between the blocks

    function updateMasonryLayout() {
      listofblockquote = document.querySelectorAll("blockquote p");
      let columns = 2; // Number of columns
      if (document.body.clientWidth < 800) {
        columns = 1;
      }
      let columnHeights = [0, 0]; // Heights of the two columns

      listofblockquote.forEach((element, index) => {
        // Determine which column the element should be placed in
        let columnIndex = Math.floor(index / maxItemsPerColumn) % columns;
        let top = columnHeights[columnIndex];
        let left = columnIndex * (columnWidthPercentage + gap / 2) + "%";

        // Set the position of the element
        element.style.position = "absolute";
        element.style.top = top + "px";
        element.style.left = left;

        // Update the height of the column
        columnHeights[columnIndex] += element.offsetHeight + gap; // gap is the space between rows
      });

      // Adjust the height of the container to fit all elements
      let container = document.querySelector("blockquote");
      container.style.position = "relative";
      container.style.height = Math.max(...columnHeights) + "px";
    }

    // Add the click event listener to toggle the class and update layout
    listofblockquote.forEach((element) => {
      if (listofblockquote.length <= maxItemsPerColumn) {
        element.style.maxWidth = "100%";
      }
      element.classList.toggle("a_ctive");
      element.addEventListener("click", () => {
        element.classList.toggle("a_ctive");
        setTimeout(() => {
          updateMasonryLayout();
        }, 200);
      });
    });
    if (listofblockquote.length > 0) {
      setTimeout(() => {
        updateMasonryLayout();
      }, 200);
    }
    // Initial layout update
  });

  let mainpage = "";
  if (
    $router.path === "/" ||
    $router.path === "/aboutus" ||
    $router.path === "/contact"
  ) {
    mainpage = "mainpage";
  } else {
    mainpage = "mainpage";
  }
  if ($router.path.includes("/player")) {
    mainpage = "mainpage  players";
  }
</script>

<div
  class="background {mainpage}"
  style=" background: url({_URL}) no-repeat center center fixed;"
>
  <div class="container {mainpage}">
    <!-- {#if $router.path != "/" && $router.path != "/aboutus" && $router.path != "/contact"}
      <Slogan />
    {/if} -->
    <div class="body">
      <svelte:component this={Body} />
    </div>
  </div>
  <Footer />
</div>

<style lang="scss">
  @use "../../styles/partial/mixins";

  .body {
    overflow: hidden;
    box-shadow: 0 0 0 7pt rgba(19, 54, 136, 0.451);
    border-top: 5px solid #e4e4e4;
    background: mixins.$Nwhite; /* linear-gradient(
      10deg,
      mixins.$Nwhite,
      rgba(244, 244, 244, 0.94)
    );*/
    border-radius: 45px;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
    margin-bottom: 320px;
    min-height: 600px;
    @include mixins.MQ(mobile) {
      border-radius: 0px;
    }
  }

  :global(.background) {
    padding-top: 120px;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    &.mainpage {
      padding-top: 15vh;
      width: 100vw;
      .body {
        border-radius: 0 !important;
      }
      &.players {
        padding-top: 110px;
        @include mixins.MQ(mobile) {
          padding-top: 130px;
        }
      }
    }

    overflow-x: hidden;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: mixins.$maxWidth;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    bottom: 0;
    left: 0;

    @include mixins.MQ(mobile) {
      margin-bottom: 160px;
    }
    &.mainpage {
      max-width: 100vw;
      bottom: 170px;
      margin-bottom: -160px;
      @include mixins.MQ(mobile) {
        margin-bottom: -10px;
      }
      &.players {
        bottom: 0;
        margin-bottom: 0;
        @include mixins.MQ(mobile) {
          margin-bottom: 170px;
          margin-top: -15px;
        }
        padding-top: 0;
        .body {
          border-radius: 0 !important;
          box-shadow: none;
        }
      }
    }
  }
  :global(.players .center) {
    display: none !important;
  }
</style>
