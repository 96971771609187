<script>
  import { Link } from "yrv";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let news = $Data.News.slice()
    .sort((a, b) => b._created - a._created)
    .slice(0, 5);
  let Prev = news[0],
    background,
    title,
    desc,
    Prevselected,
    _i = 0,
    ELEMENTS_all = [],
    overslider;

  let loop;
  const initInterval = () => {
    clearInterval(loop);
    loop = setInterval(() => {
      _i++;
      if (_i >= news.length) {
        _i = 0;
      }
      Prev = news[_i];
      resetanimations();
      if (!Prevselected) {
        ELEMENTS_all[0].classList.remove("selected");
      } else {
        Prevselected.classList.remove("selected");
      }
      Prevselected = ELEMENTS_all[_i];
      if (overslider) overslider.scrollTop = ELEMENTS_all[_i].offsetTop - 10;
      else clearInterval(loop);
      Prevselected.classList.add("selected");
    }, 8000);
  };
  initInterval();
  const addelement = (node) => {
    ELEMENTS_all.push(node);
  };
  const ChangeTab = (node, i) => {
    overslider.scrollTop = node.target.offsetTop - 10;
    if (node.target.classList.contains("selected")) return;
    initInterval();
    _i = i;
    Prev = news[i];
    resetanimations();
    // console.log(node.target);
    if (Prevselected) {
      Prevselected.classList.remove("selected");
    } else {
      ELEMENTS_all[0].classList.remove("selected");
    }
    Prevselected = node.target;
    node.target.classList.add("selected");
  };

  const resetanimations = () => {
    if (!background) return;
    background.classList.remove("zoombg");
    title.classList.remove("popup");
    desc.classList.remove("popup");
    title.classList.remove("fadein");
    desc.classList.remove("fadein");
    setTimeout(() => {
      background.classList.add("zoombg");
      title.classList.add("fadein");
      desc.classList.add("fadein");
    }, 10);
  };
</script>

<div class="fullWidth section">
  <div
    class="Title appearleft"
    on:click={() => {
      window.location.href = "/news";
    }}
  >
    <i class="far fa-newspaper" />
    {langs.Mnews}
  </div>

  <div class="slider">
    <div
      class="fullSlide zoombg"
      style="background-image:url('https://www.gffootballplayers.com/{Prev
        .Preview_Image.path}')"
      bind:this={background}
      on:click={() => {
        window.open("/news/" + Prev._id, "_blank");
      }}
    >
      <div class="Desc popup" bind:this={desc}>
        <div class="name popup" bind:this={title}>{Prev.Title}</div>
        <!-- {Prev.ShortDescription} -->
      </div>
      <Link href="/news/{Prev._id}">
        <div class="button">{langs.Pmorebutton}</div>
      </Link>
    </div>

    <div class="overlaySlides zoom" bind:this={overslider}>
      {#each news as item, i}
        <div
          class="box popup {i == 0 ? 'selected' : ''}"
          id="box_{i}"
          use:addelement
          on:click={() => {
            ChangeTab(event, i);
          }}
        >
          <div
            class="box_img"
            style="background-image:url('https://www.gffootballplayers.com/{item
              .Preview_Image.path}')"
          />
          <div class="box_head">{item.Title}</div>
          <!-- <div class="box_desc">{item.ShortDescription}</div> -->
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  :global(.slider) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .section {
    margin-top: 5px;
    position: relative;
  }
  .fullSlide {
    width: 100%;
    height: 580px;
    padding-top: 20px;
    cursor: pointer;
    background-size: 100%;
    background-repeat: no-repeat !important;
    background-position: 0 -80px !important;
    background-color: #16192b;
    @include mixins.MQ(mobile) {
      background-size: cover !important;
      background-position: top !important;
      top: -130px;
      position: relative;
    }
  }
  .Title {
    position: absolute;
    left: -5px;
    z-index: 2;
    padding: 10px 0 10px 30px;
    font-size: 20px;
    font-weight: 999;
    color: mixins.$color-2;
    background: mixins.$Nwhite;
    width: 230px;
    margin-bottom: 20px;
    transform: skewX(-10deg);
    border-bottom-right-radius: 22px;

    @include mixins.MQ(mobile) {
      padding: 15px 0 10px 35px;
      font-size: 15px;
    }
  }
  .overlaySlides {
    scroll-behavior: smooth;
    height: auto;
    width: 32%;
    background: mixins.$Nwhite;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    @include mixins.MQ(mobile) {
      top: -49px;
      position: relative;
      max-height: 560px;
      width: 40%;
    }
  }
  .name {
    color: mixins.$Nwhite;
    font-size: 20px;
    text-shadow: 0 0 10px black;
    font-weight: 700;
    width: 100%;
    text-decoration: underline;
    @include mixins.MQ(mobile) {
      font-size: 13px;
    }
  }
  .Desc {
    position: absolute;
    bottom: 0px;
    left: 1px;
    z-index: 10;
    color: mixins.$Nwhite;
    font-size: 14px;
    text-shadow: 0 0 10px #000;
    font-weight: 500;
    width: 75.65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-content: center;
    line-height: 27px;
    padding: 15px;
    padding-bottom: 70px;
    padding-left: 32px;
    background: linear-gradient(
      44deg,
      rgb(35 38 49 / 36%),
      rgb(43 49 68 / 35%) 25%,
      #2d4081bd 50%,
      rgba(35, 85, 200, 0.5) 85%,
      rgb(0 110 255 / 76%)
    );
    @include mixins.MQ(mobile) {
      background: linear-gradient(
        44deg,
        #384fa3,
        rgb(70 100 194 / 90%) 25%,
        #2859be 50%,
        #2355c8 85%,
        #052b5c
      );
      bottom: -166px;
      font-size: 12px;
      line-height: 21px;
      min-height: 166px;
      width: 100%;
    }
  }
  .button {
    position: absolute;
    bottom: 25px;
    left: 30px;
    z-index: 10;
    color: mixins.$Nwhite;
    background: mixins.$color-4;
    font-size: 14px;
    text-shadow: 0 0 10px #000;
    font-weight: 100;
    padding: 5px 25px;
    border-radius: 6px;
    @include mixins.MQ(mobile) {
      bottom: -140px;
    }
  }
  .box {
    width: calc(100% - 10px);
    height: 77%;
    @include mixins.MQ(mobile) {
      width: calc(100% - 20px);
      height: calc(63% - 10px);
    }
    background: mixins.$Nwhite;
    margin: 8px;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 1px 5px rgba(61, 158, 215, 0.63);
    }
  }
  .selected {
    box-shadow: 0 0 1px 5px rgba(61, 158, 215, 0.63);
  }
  .box_img {
    width: 55%;
    height: 100px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #16192b;
    float: left;
    pointer-events: none;
    @include mixins.MQ(mobile) {
      width: 100%;
      height: 97px;
    }
  }
  .box_head,
  .box_desc {
    float: right;
    width: 40%;
    @include mixins.BP(Ms) {
      width: 65%;
    }
    @include mixins.BP(Mm) {
      width: 65%;
    }
    @include mixins.BP(Ml) {
      width: 65%;
    }
    overflow-wrap: break-word;
    margin: 5px;
    pointer-events: none;
  }
  .box_head {
    font-weight: 900;
    font-size: 10px;
    max-height: 90px;
    overflow: hidden;
    @include mixins.MQ(mobile) {
      display: none;
    }
  }
  .box_desc {
    font-size: 0.45vw;
    height: 36px;
    overflow: hidden;
    line-height: 12px;
    @include mixins.MQ(mobile) {
      font-size: 11px;
    }
  }
  .fullSlide.zoombg {
    height: 580px;

    @include mixins.MQ(mobile) {
      height: 270px !important;
    }
  }
  .slider {
    @include mixins.MQ(mobile) {
      height: 590px;
    }
  }
</style>
