<script>
  import { navigateTo } from "yrv";
  import { onMount } from "svelte";
  import SVG from "@services/SVG.svelte";
  import { detectMob } from "@stores/app_store.js";
  import { router } from "yrv";
  import { get_lang } from "@stores/app_store.js";
  export let Player, langs;
</script>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .stats {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 18px;
    width: 100%;
    height: 100%;
    background: mixins.$Nwhite;
    background: linear-gradient(
      180deg,
      rgba(43, 49, 68, 0),
      rgba(255, 255, 255, 0.5) 58%,
      rgba(43, 49, 68, 0) 64%,
      rgba(43, 49, 68, 0)
    ) !important;
    @include mixins.MQ(mobile) {
      font-size: 13px;
    }
    h1 {
      width: 100%;
    }
    text-align: center;
    font-weight: 100;
    display: flex;
    .wrapper {
      padding: 0 20px;
      border-right: 1px solid mixins.$color-2;
      line-height: 24px;
      @include mixins.MQ(mobile) {
        width: 33%;
        margin: 7px 0;
        line-height: 15px;
        padding: 0 11px;
      }
      &:last-child {
        border-right: 0px solid white;
      }
      .top {
        font-size: 18px;
        img {
          width: 100%;
          height: 100px;
          object-fit: contain;
        }
        @include mixins.MQ(mobile) {
          font-size: 13px;
        }
        font-weight: 990;
      }
    }
  }
</style>

{#if Player && Player.prevTeam && Player.prevTeam.length != 0}
  <div class="stats zoom">
    <h1>{langs.PproigoumenesOmades}</h1>
    {#each Player.prevTeam as item, i}
      <div class="wrapper zoom">
        <div class="top">
          <img
            src="https://www.gffootballplayers.com/{item.value.team.Photo.path}"
            alt={item.value.team.Fullname} />
        </div>
        <div class="bottom">
          {item.value.xronologia}
          <br />
          {item.value.team.Fullname}
        </div>
      </div>
    {/each}
  </div>
{/if}
