<script>
  import { Link } from "yrv";
  import { onMount } from "svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let submenu = $Data.submenu;
  let active = false;
  const openclose = () => {
    active = !active;
  };

  let isOpen = false;

  function toggleMenu() {
    isOpen = !isOpen;
  }
</script>

<div class="Navcontainer" class:active>
  <div class="lefticon">
    <img
      src="https://www.gffootballplayers.com/images/onlylogo.png"
      loading="lazy"
      alt="logo"
    />
    <div class="font" style="color:white">G&F SCOUTER</div>
  </div>

  <div class="menuButton" on:click={openclose}>
    <div class="bar1" />
    <div class="bar2" />
    <div class="bar3" />
  </div>

  <nav class="main-nav">
    <ul>
      {#each langs.LeftMenu as item, i}
        {#if item.value.active}
          {#if item.value.url == "/player"}
            <div class="menuItem dropmenu {isOpen ? 'open' : ''}" on:click={toggleMenu}>
              <li>
                <i class={item.value.icon} />
                <Link class="" href={item.value.url}>{item.value.name}</Link>
                <i class="fas fa-angle-down" />
              </li>
              <div class="submenu">
                <div class="content">
                  {#each submenu as item_}
                    {#if item_.active}
                      <Link class="Link" href="/player?cat={item_.ID}">
                        <div class="subItem">{item_["name_" + get_lang()]}</div>
                      </Link>
                    {/if}
                  {/each}
                </div>
              </div>
            </div>
          {:else}
            <li>
              <Link class="Link" href={item.value.url}>
                <i class={item.value.icon} />
                <div class="menuItem">{item.value.name}</div>
              </Link>
            </li>
          {/if}
        {/if}
      {/each}
      {#each langs.RightMenu as item, i}
        {#if item.value.active}
          <li>
            <Link class="Link" href={item.value.url}>
              <i class={item.value.icon} />
              <div class="menuItem">{item.value.name}</div>
            </Link>
          </li>
        {/if}
      {/each}
    </ul>
  </nav>
</div>

<style lang="scss">
  @use "../../styles/partial/mixins";

  .Navcontainer {
    z-index: 999;
    position: absolute;
    top: 60px;
    left: -2px;
    i {
      font-size: 22px;
      // margin: 0 12px;
      float: right;
      width: 18px;
      &.far,
      &.fas.fa-user-friends {
        width: 33px;
        float: left;
        margin-right: 20px;
      }
    }
    :global(.Link) {
      color: white;
    }
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-radius: 20px;
      overflow: hidden;
    }
    nav.main-nav {
      display: none;
    }
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 30px;
    height: 4px;
    background-color: rgb(255, 255, 255);
    margin: 6px 5px;
    transition: 1s;
  }

  .menuButton {
    transform: scale(0.6);
    margin: 10px;
    display: inline-block;
    cursor: pointer;
  }
  .lefticon {
    position: absolute;
    img {
      width: 0px;
      margin: 10px;
      font-size: 0px;
      opacity: 0;
      transition:
        width 1s,
        font-size 0.5s,
        opacity 0.2s;
    }
    .font {
      width: 0px;
      margin: 10px;
      font-size: 0px;
      opacity: 0;
      transition:
        width 0.1s,
        font-size 0.5s,
        opacity 0.2s;
    }
  }
  .dropmenu {
    .submenu {
      max-height: 0px;
      transition: max-height .5s;
      overflow: hidden;
    }
  }
  .dropmenu {
    &.open {
      .submenu {
        max-height: 200px;
      }
    }
  }
  .active {
    .menuButton {
      transform: scale(0.7) translate(-90px, 5px);
    }
    animation: appearleft 1s;
    .lefticon {
      position: absolute;
      .font {
        font-size: 18px;
        white-space: nowrap !important;
        line-height: 2;
        color: #3a3caf !important;
        font-weight: bold;
        float: right;
        opacity: 1;
      }
      img {
        width: 35px;
        opacity: 1;
        margin: 10px;
      }
    }
    nav.main-nav {
      display: block;
      padding: 60px 10px 10px;
      background: #dfeeff;
      width: 100vw;
      height: 1000px;
      font-size: 16px;
      .menuItem {
        border: 2px solid #ffffff;
        background: #ffffff;
        width: 100%;
        li {
          *,
          a {
            color: #3a3caf !important;
          }
        }
        .submenu {
          .subItem {
            padding: 6px;
            background: #3a3caf;
            border: 2px solid #ffffff;
            padding-left: 20px;
            border-radius: 15px;
            margin-left: 45px;
          }
        }
      }
      li {
        border: 2px solid #ffffff;
        border-top: 2px solid #dfeeff;
        background: #ffffff;
        width: 100%;
        padding: 10px;
        a {
          * {
            color: #3a3caf !important;
          }
        }
      }
    }
    .menuButton {
      float: right;
      display: inline-block;
      cursor: pointer;
    }
    .bar1 {
      transform: rotate(-45deg) translate(-9px, 6px);
      background-color: rgb(58, 60, 175);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      transform: rotate(45deg) translate(-8px, -8px);
      background-color: rgb(58, 60, 175);
    }
  }
</style>
