<script>
  import ProgressBar from "@okrad/svelte-progressbar";
  import Slogan from "@components/container/Slogan.svelte";

  import { navigateTo } from "yrv";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let idrites = $Data.Idrites;
  let TopPart, header, body;

  let isActive = false;
  let videoPlaceClass = "";

  function handleClick() {
    isActive = !isActive;
    videoPlaceClass = isActive ? "active" : "";
  }
</script>

<div class="blueslogan" style="color:black">
  <Slogan />
</div>
<div class="fullWidth inner">
  <div class="about_wrapper">
    <div class="about_left">
      <div class="title popup">
        <h1>{@html langs.welcomeSection.title}</h1>
        <h4>{@html langs.welcomeSection.subtitle}</h4>
      </div>
      <div class="description appearleft">
        {@html langs.welcomeSection.description}
        <b
          class="more fadein"
          on:click={() => {
            navigateTo("/aboutus", { replace: true });
          }}
        >
          {langs.welcomeSection.button}
          <SVG key={"arrowright"} width={"10px"} />
        </b>
      </div>
    </div>
    <!-- <div class="stats popup">
    <div class="WmbottomPart">
      <div class="headPart">
        <div class="___">{langs.welcomeSection.perc_1.title}</div>
        <div class="___">{langs.welcomeSection.perc_2.title}</div>
        <div class="___">{langs.welcomeSection.perc_3.title}</div>
      </div>
      <ProgressBar
        style="radial"
        series={[langs.welcomeSection.perc_1.percent]}
        thickness={5}
        labelColor="#004082"
        textSize={110}
        width={90}
        thresholds={[
          {
            till: 50, //Color stays red from 0% to 50%
            color: "#004082",
          },
          {
            till: 100, //Color goes green from 51% to 100%
            color: "#004082",
          },
        ]}
      />
      <ProgressBar
        style="radial"
        series={[langs.welcomeSection.perc_2.percent]}
        thickness={5}
        width={100}
        labelColor="#004082"
        textSize={90}
        thresholds={[
          {
            till: 50, //Color stays red from 0% to 50%
            color: "#004082",
          },
          {
            till: 100, //Color goes green from 51% to 100%
            color: "#004082",
          },
        ]}
      />
      <ProgressBar
        style="radial"
        series={[langs.welcomeSection.perc_3.percent]}
        thickness={5}
        labelColor="#004082"
        textSize={110}
        width={90}
        thresholds={[
          {
            till: 50, //Color stays red from 0% to 50%
            color: "#004082",
          },
          {
            till: 100, //Color goes green from 51% to 100%
            color: "#004082",
          },
        ]}
      />
    </div>
  </div> -->
    <div class="videoPlace popup {videoPlaceClass}">
      <svg
        on:click={handleClick}
        viewBox="-4 -5 35 35"
        xmlns="http://www.w3.org/2000/svg"
        fill=""
        stroke=""
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <g
            id="Group_710"
            data-name="Group 710"
            transform="translate(-200 -100)"
          >
            <path
              id="Path_1492"
              data-name="Path 1492"
              d="M224,126h-5v-2h5v-5h2v5A2.006,2.006,0,0,1,224,126Zm-4-4H208a1,1,0,0,1,0-2h12V106H206v11a1,1,0,0,1-2,0V106a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2v14A2.006,2.006,0,0,1,220,122Zm-18-20v5h-2v-5a2.006,2.006,0,0,1,2-2h5v2Z"
              fill="#ffffff"
            ></path>
          </g>
        </g></svg
      >
      <video autoplay="true" nocontrols loop muted>
        <source
          src="https://gffootballplayers.com/images/aboutus_p.mp4"
          type="video/mp4"
        />
        <!-- <source src={langs.RegisterNow?.Videolink} type="video/mp4" /> -->
        Your browser does not support HTML video.
      </video>
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .videoPlace.popup {
    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #1654c540;
      mix-blend-mode: overlay;
      pointer-events: none;
    }
    svg {
      position: absolute;
      height: 70px;
      width: 70px;
      background: cornflowerblue;
      padding: 12px;
      border-radius: 100%;
      top: 10px;
      left: 10px;
      cursor: pointer;
      z-index: 2;
    }
    width: 100%;
    height: 480px;
    position: relative;
    // top: 80px;
    // right: 27vh;
    overflow: hidden;
    border-radius: 20px;
    transition: 1s;
    border: 12px solid #004082;
    video {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
    &.active {
      border: 0;
      width: 100vw;
      height: 100vh;
      position: fixed;
      right: 0;
      z-index: 9999;
      top: 70px;
      video {
        @include mixins.MQ(mobile) {
          object-fit: contain;
        }
      }
    }
    @include mixins.MQ(mobile) {
      width: 100%;
      height: 210px;
      position: relative;
      top: -20px;
      right: 0;
      overflow: hidden;
      border-radius: 0;
      transition: 1s;
      border: 12px solid #004082;
      svg {
        height: 50px;
        width: 50px;
      }
    }
  }
  .inner {
    .about_wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: row !important;
      max-width: 1350px;
      margin: 0 auto;
      @include mixins.MQ(mobile) {
        flex-wrap: wrap;
      }
      .about_left {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-direction: column !important;
      }
    }
    padding: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    margin-bottom: 50px;
    background-position: bottom;
    height: 100%;
    background-size: cover;
    // linear-gradient(265deg, #004082 8%, #043262 54.6%, #fff 55.1%)
    background: linear-gradient(265deg, #004082 56%, #fff 56.1%);
    @include mixins.MQ(mobile) {
      background: linear-gradient(0deg, #004082 44%, white 44.2%);
    }
  }
  .___ {
    text-align: center;
    // font-size: 13px;
    width: 130px;
    font-weight: bold;
    // text-shadow: 3px 3px 5px black;
    @include mixins.MQ(mobile) {
      text-align: left;
      font-size: 12px;
      width: 100px;
      margin-top: -18px;
    }
    @include mixins.MQ(mobileM) {
      text-align: center;
      font-size: 11px;
      width: 120px;
      height: 32px;
      margin-top: -16px;
    }
  }
  .description {
    font-family: Comfortaa, sans-serif;
    font-size: 25px;
    line-height: 37px;
    width: 100% !important;
    @include mixins.MQ(mobile) {
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
    }
  }
  :global(.description p) {
    @include mixins.MQ(pc) {
      width: 75%;
    }
    @include mixins.MQ(mobile) {
      background: white;
      padding: 0 10px;
      font-size: 16.5px;
      line-height: 21px;
    }
  }
  .title h4 {
    line-height: 0px;
  }
  .stats,
  .description,
  .title {
    width: 90%;
    max-width: mixins.$maxWidth;
  }

  .title {
    height: max-content;
    width: 100% !important;
    @include mixins.MQ(mobile) {
      padding: 0 10px;
    }
    h1 {
      font-size: 50px;
      line-height: 20px;
      @include mixins.MQ(mobile) {
        font-size: 43px;
        @include mixins.BP(Ms) {
          font-size: 25px;
        }
        bottom: 65px;
      }
    }
  }

  .more {
    cursor: pointer;
    position: relative;
    background: mixins.$color-1;
    padding: 9px 25px;
    z-index: 99;
    color: white;
    font-size: 13px;
    font-weight: 99;
    border-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    width: 200px;
    margin: 20px 0px;
    left: -5px;
    bottom: -8px;
    @include mixins.MQ(mobile) {
      bottom: 13px;
      font-size: 12px;
      padding: 7px 25px;
      line-height: 18px;
      left: 10px;
    }
    text-shadow: 3px 3px 5px black;
    box-shadow: 0 0 15px 0px rgb(0 0 0 / 67%);
    transition: left 1s;
    &:hover {
      left: -15px;
      @include mixins.MQ(mobile) {
        left: 5px;
      }
    }
    :global(svg) {
      margin-left: 10px;
    }
  }
</style>
