<script>
  import { navigateTo } from "yrv";
  import { onMount, createEventDispatcher } from "svelte";
  import SVG from "@services/SVG.svelte";
  import { detectMob } from "@stores/app_store.js";
  import { router } from "yrv";
  import { get_lang, getAverageRGB } from "@stores/app_store.js";
  export let players, langs;
  const dispatch = createEventDispatcher();
</script>

<div class="scroller">
  <div class="inner">
    {#each Array.from(Array(Math.max(players.length, 13)), (x, index) => {
      return players[index] || null;
    }) as item, i}
      {#if item && $router.params.slug == item._id}
        <div class="player active">
          <img
            alt={item.IconImage.path}
            src="https://www.gffootballplayers.com/{item.IconImage.path}"
          />
          <div class="name">{item["Onoma" + get_lang()]}</div>
        </div>
      {:else if !item}{:else}
        <div
          class="player"
          on:click={() => {
            navigateTo("/player/" + item._id);
            dispatch("playerClicked");
          }}
        >
          <img
            alt={item.IconImage.path}
            src="https://www.gffootballplayers.com/{item.IconImage.path}"
          />
          <div class="name">{item["Onoma" + get_lang()]}</div>
        </div>
      {/if}
    {/each}
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .scroller {
    width: 100%;
    @include mixins.MQ(pc) {
      // box-shadow: inset -12px -7px 11px 0px rgb(0 0 0 / 15%);
    }

    .inner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-content: center;
      align-items: center;
      padding-bottom: 10px;
      gap: 10px;
      @include mixins.MQ(mobile) {
        gap: 3px;
      }
      .player {
        width: 31%;
        @include mixins.BP(Ms) {
          width: 48%;
        }
        @include mixins.BP(Mm) {
          width: 48%;
        }
        height: 10vw;
        text-align: center;
        cursor: pointer;
        border: 2px solid #dce3e8;
        background: #e2e4e8;
        transition:
          border 1s,
          background 1s;
        background: linear-gradient(
          277deg,
          rgb(63 103 182) 25%,
          #517dba 27%,
          rgb(28 55 121) 83%
        );
        border-radius: 22px;
        position: relative;
        padding: 0 0 30px;
        overflow: hidden;
        @include mixins.MQ(mobile) {
          height: 155px;
          padding: 12px 0px;
          padding-top: 0;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
          filter: contrast(110%) drop-shadow(-1px -4px 0 cyan)
            drop-shadow(4px 0 0 white)
            drop-shadow(-7px 10px 0px rgba(50, 100, 200, 0.5));
          @include mixins.MQ(mobile) {
            filter: contrast(110%) drop-shadow(-1px -4px 0 cyan);
          }
        }
        .name {
          font-size: 15px;
          background: #20418e;
          color: white;
          font-weight: bold;
          padding: 8px 21px;
          z-index: 1;
          border-radius: 2px;
          position: absolute;
          bottom: 0;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          align-content: center;
          width: 100%;
          @include mixins.MQ(mobile) {
            border-radius: 0px;
            font-size: 13px;
          }
        }
      }
      .player:hover,
      .player.active {
        filter: drop-shadow(2px 4px 6px gray);
        background: linear-gradient(0deg, #09c4ff 0, #fff 27%, #02c7ff 83%);
        .name {
          background: #09c4ff;
          @include mixins.MQ(mobile) {
            left: -3px;
          }
        }
      }
    }
  }
</style>
