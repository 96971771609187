<script>
  import { navigateTo } from "yrv";
  import { onMount } from "svelte";
  import SVG from "@services/SVG.svelte";
  import { detectMob } from "@stores/app_store.js";
  import { router } from "yrv";
  import { get_lang } from "@stores/app_store.js";
  export let playerTimeline, langs, timeline;
  let stats,
    sliderCurrent = 0,
    PrevsliderCurrent = 0,
    prevactive;
  $: $router && routerChanged();

  const routerChanged = () => {
    sliderCurrent = 0;
    PrevsliderCurrent = 0;
    if (timeline) timeline.style.width = "100%";
    if (timeline) timeline.style.maxWidth = "960px";
  };
  onMount(() => {
    if (timeline) timeline.style.width = "100%";
    if (timeline) timeline.style.maxWidth = "960px";
  });
  const ChangeTab = (e, i) => {
    if (prevactive) prevactive.classList.remove("active");
    e.target.classList.add("active");
    if (stats) {
      stats.classList.remove("zoom");
      setTimeout(() => {
        stats.classList.add("zoom");
      }, 100);
    }
    prevactive = e.target;
    sliderCurrent = i;
    if (sliderCurrent > PrevsliderCurrent) {
      timeline.parentElement.scrollLeft = e.target.offsetLeft + 100;
    } else {
      timeline.parentElement.scrollLeft = e.target.offsetLeft - 200;
    }
    PrevsliderCurrent = sliderCurrent;
  };
</script>

{#if playerTimeline && playerTimeline.history && playerTimeline.history.length != 0 && playerTimeline.MaxHistory > 0}
  <div class="container">
    <ul class="timeline" bind:this={timeline}>
      {#each playerTimeline.history as item, i}
        {#if item.value["Title" + get_lang()] != ""}
          {#if i < playerTimeline.MaxHistory}
            <li
              class="appearright"
              class:active={i === PrevsliderCurrent}
              on:click={(e) => {
                ChangeTab(e, i);
              }}
            >
              {item.value["Title" + get_lang()]}
            </li>
          {/if}
        {/if}
      {/each}
    </ul>
  </div>

  <div class="stats zoom" bind:this={stats}>
    {#if playerTimeline.history[PrevsliderCurrent].value.symetoxes != "" && playerTimeline.history[PrevsliderCurrent].value.symetoxes}
      <div class="wrapper zoom">
        <div class="top">
          <i class="fas fa-map-marker-alt" />
          {langs.Psymetoxes}
        </div>
        <div class="bottom">
          {playerTimeline.history[PrevsliderCurrent].value.symetoxes}
        </div>
      </div>
    {/if}
    {#if playerTimeline.history[PrevsliderCurrent].value.xronos != "" && playerTimeline.history[PrevsliderCurrent].value.xronos}
      <div class="wrapper zoom">
        <div class="top">
          <i class="fas fa-stopwatch" />
          {langs.PxonosSymm}
        </div>
        <div class="bottom">
          {playerTimeline.history[PrevsliderCurrent].value.xronos}
        </div>
      </div>
    {/if}
    {#if playerTimeline.history[PrevsliderCurrent].value.goal != "" && playerTimeline.history[PrevsliderCurrent].value.goal}
      <div class="wrapper zoom">
        <div class="top">
          <i class="fas fa-crosshairs" />
          {langs.Pgkol}
        </div>
        <div class="bottom">
          {playerTimeline.history[PrevsliderCurrent].value.goal}
        </div>
      </div>
    {/if}
    {#if (playerTimeline.history[PrevsliderCurrent].value.assists != "" && playerTimeline.history[PrevsliderCurrent].value.assists) || (playerTimeline.history[PrevsliderCurrent].value.pases != "" && playerTimeline.history[PrevsliderCurrent].value.pases)}
      <div class="wrapper zoom">
        <div class="top">
          <i class="fas fa-hands-helping" />
          {langs.PtelikesAssists}
        </div>
        <div class="bottom">
          {playerTimeline.history[PrevsliderCurrent].value.assists |
            playerTimeline.history[PrevsliderCurrent].value.pases}
        </div>
      </div>
    {/if}
    {#if playerTimeline.history[PrevsliderCurrent].value.kitrines != "" && playerTimeline.history[PrevsliderCurrent].value.kitrines}
      <div class="wrapper zoom">
        <div class="top">
          <i class="fas fa-square" style="color:yellow;" />
          {langs.PkitrinesKartes}
        </div>
        <div class="bottom">
          {playerTimeline.history[PrevsliderCurrent].value.kitrines}
        </div>
      </div>
    {/if}
    {#if playerTimeline.history[PrevsliderCurrent].value.kokines != "" && playerTimeline.history[PrevsliderCurrent].value.kokines}
      <div class="wrapper zoom">
        <div class="top">
          <i class="fas fa-square" style="color:red;" />
          {langs.PkokinesKartes}
        </div>
        <div class="bottom">
          {playerTimeline.history[PrevsliderCurrent].value.kokines}
        </div>
      </div>
    {/if}
  </div>
{/if}

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .container {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: flex;
    justify-content: center;
    background: white;
    @include mixins.MQ(mobile) {
      justify-content: start;
    }
  }

  ul:nth-child(1) {
    color: mixins.$color-2;
  }

  .timeline {
    padding: 0;
    display: flex;
    justify-content: space-evenly;

    //line
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 40px;
      width: 100%;
      height: 5px;
      background: mixins.$epic;
      z-index: -999;
    }
    li {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 48%;
      min-width: 130px;
      height: 60px;
      font-size: 12px;
      font-weight: 800;
      list-style: none;
      text-align: center;
      cursor: pointer;
      background: white;
      align-items: center;
      border-radius: 10px;
      border: 2px solid;
      transition:
        width 0.5s ease,
        height 0.5s ease,
        background 1s;
      z-index: 1;
      padding: 6px;
      &:before {
        content: "";
        width: 20px;
        height: 0px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid #1034a6;
        display: block;
        margin: 16px auto;
        background: #f5f5f5;
        position: absolute;
        z-index: 0;
        top: 39px;
        left: calc(50% - 12px);
        transition: opacity 0.5s ease;
        opacity: 0;
      }
      &.active {
        color: mixins.$color-1;
        border: 1px solid mixins.$color-1;
        background: mixins.$color-2;
        color: mixins.$white;
        font-weight: 100;
        padding: 6px 10px;
        width: 48%;
        min-width: 130px;
        &:before {
          opacity: 1;
          content: "";
          width: 20px;
          height: 0px;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid #1034a6;
          display: block;
          margin: 16px auto;
          background: #f5f5f5;
          position: absolute;
          z-index: 0;
          top: 39px;
          left: calc(50% - 12px);
        }
      }
    }
  }
  .stats {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 100%;
    position: relative;
    // bottom: 10px;
    background: mixins.$white;
    height: 100px;
    box-shadow: 0px 14px 9px 0px rgba(0, 0, 0, 0.08);
  
    @include mixins.MQ(mobile) {
      font-size: 13px;
      flex-wrap: wrap;
      height: 150px;
    }
    text-align: center;
    font-weight: 100;
    display: flex;
    .wrapper {
      padding: 0 20px;
      border-right: 1px solid mixins.$color-2;
      line-height: 24px;
      @include mixins.MQ(mobile) {
        width: 33%;
        margin: 7px 0;
        line-height: 15px;
        padding: 0 11px;
      }
      &:last-child {
        border-right: 0px solid white;
      }
      .top {
        font-size: 18px;
        @include mixins.MQ(mobile) {
          font-size: 13px;
        }
        font-weight: 990;
      }
    }
  }
</style>
