// SECTION IMPORTS
import { GetData, GetLang } from "@services/gff_api.js";
import { derived, writable, get } from "svelte/store";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/el.js";
export let maxItemsPerColumn = 4; // Maximum number of items per column
moment.locale("El");
export const get_lang = () => {
  return GetLang();
};

export const set_maxItemsPerColumn = (a) => {
  maxItemsPerColumn = a;
};

export const get_maxItemsPerColumn = (a) => {
  return maxItemsPerColumn;
};

export const goto_url = (url) => {
  let win = window.open(url, "_blank");
  win.focus();
};

export const Data = (function () {
  const { subscribe, set, update } = writable({});
  return {
    subscribe,
    set,
    update,
    setInit: () => {
      GetData(GetLang()).then((ret) => {
        set(ret);
        // console.log(ret);
      });
    },
    ChangeLang: (a) => {
      Cookies.set("Lang", a, { expires: 300 });
      window.location.reload();
    },
  };
})();

export const getAverageRGB = (url, default_color) => {
  if (typeof default_color === "undefined") {
    default_color = "#000";
  }

  // create a dom
  url = encodeURI(url);
  let img = document.createElement("img");
  img.src = url;
  img.id = "dominantColourImg";
  img.style.display = "none";
  // work out the background image's avg colour
  var blockSize = 5, // only visit every 5 pixels
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  /*if (!context) {
        return default_color;
    }*/

  height = canvas.height = img.naturalHeight || img.offsetHeight || img.height;
  width = canvas.width = img.naturalWidth || img.offsetWidth || img.width;

  context.drawImage(img, 0, 0);
  // console.log(url);
  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    /* security error, img on diff domain */
    return default_color;
  }

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return "rgb(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ")";
};
export const detectMob = () => {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;
  return x < 860 ? true : false;
};
