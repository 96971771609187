<script>
  import { Link } from "yrv";
  import { Data, get_lang, detectMob } from "@stores/app_store.js";
  import MenuMob from "./menuMobile.svelte";
  let langs = $Data.langs[0];
  let submenu = $Data.submenu;
</script>

{#if detectMob()}
  <MenuMob />
{:else}
  <div class="menu_wrapper_left">
    {#each langs.LeftMenu as item, i}
      {#if item.value.active}
        {#if item.value.url == "/player"}
          <div class="menuItem dropmenu">
            <Link class="Link" href={item.value.url}>{item.value.name}</Link>
            <div class="submenu">
              <div class="content">
                {#each submenu as item_}
                  {#if item_.active}
                    <a class="Link" href="/player?cat={item_.ID}">
                      <div class="subItem">{item_["name_" + get_lang()]}</div>
                    </a>
                  {:else}
                    <a class="Link" href="/player">
                      <div class="subItem">{item_["name_" + get_lang()]}</div>
                    </a>
                  {/if}
                {/each}
              </div>
            </div>
          </div>
        {:else}
          <Link class="Link" href={item.value.url}>
            <div class="menuItem">{item.value.name}</div>
          </Link>
        {/if}
      {/if}
    {/each}
  </div>
  <div class="site-logo-area" />
  <div class="menu_wrapper_right">
    {#each langs.RightMenu as item, i}
      {#if item.value.active}
        <Link class="Link" href={item.value.url}>
          <div class="menuItem">{item.value.name}</div>
        </Link>
      {/if}
    {/each}
  </div>
{/if}

<style lang="scss">
  @use "../../styles/partial/mixins";

  .menuItem {
    text-align: center;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 10px;
    font-size: 12px;
    background: mixins.$scrollbarBG;
    font-weight: 999;
    &:hover {
      background: mixins.$Nwhite;
      color: mixins.$color-2;
      transition: background 1s;
    }
    :global(a.Link) {
      height: 58px;
      display: flex;
      align-items: center;
    }
  }
  .menu_wrapper_left {
    :global(a) {
      text-decoration: none;
      color: mixins.$Nwhite;
    }
    float: left;
    display: flex;
    justify-content: space-between;
    color: mixins.$white;
    width: 100%;
    font-size: 85%;
  }
  .site-logo-area {
    width: 300px;
  }
  .menu_wrapper_right {
    :global(a) {
      color: mixins.$Nwhite;
    }
    float: left;
    display: flex;
    justify-content: space-between;
    color: mixins.$white;
    width: 100%;
  }
  .dropmenu {
    &:hover :global(a) {
      color: mixins.$color-2;
    }
    &:hover .submenu .content {
      visibility: visible;
      height: 210px !important;
      :global(a) {
        opacity: 1;
      }
    }
  }
  .submenu {
    width: 170px;
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: start;
    top: 120px;
    transform: translateX(-10px);

    // left: 0;
    color: mixins.$color-2;
    background: #e8eef4;
    font-weight: 999;
    .content {
      visibility: hidden;
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 0;
      transition: height 0.5s cubic-bezier(1, 0.1, 0.165, 1);
      .subItem {
        // margin: 0 10px;
      }
      :global(a) {
        color: mixins.$color-2;
        height: 60px;
        padding: 10px;
        width: 100%;
        opacity: 0;
        transition:
          opacity 2s,
          background 1s;
        &:hover {
          background: mixins.$color-2;
          color: mixins.$Nwhite;
        }
      }
    }
  }
</style>
