<script>
    import { navigateTo } from "yrv";
    import SVG from "@services/SVG.svelte";
    import { Data, get_lang } from "@stores/app_store.js";
    let langs = $Data.langs[0];
  </script>
  
  <div class="fullWidth inner">
    <video autoplay="true" nocontrols loop muted>
      <source
        src={langs.RegisterNow?.Videolink} 
        type="video/mp4"
      />
      <!-- <source src={langs.RegisterNow?.Videolink} type="video/mp4" /> -->
      Your browser does not support HTML video.
    </video>
  
  </div>
  
  <style lang="scss">
    @use "../../../styles/partial/mixins";
  
    .inner {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      top: 0px;
      left: 49.9%;
      transform: translateX(-50%);
      justify-content: center;
      text-align: center;
      align-content: center;
      align-items: center;
      background-position: bottom;
      height: 60vh;
      width: 100vw;
      background-size: cover;
      background-color: #000;
      z-index: 0;
      flex-direction: column;
  
      @include mixins.MQ(mobile) {
        top: 0;
        height: 60vh;
        width: 100vw;
        &:before {
          bottom: -86px;
          height: 125px;
        }
      }
  
      video,
      iframe {
        position: absolute;
        width: 100%;
        height: 110%;
        top: 0;
        left: 0;
        object-fit: cover;
        background: linear-gradient(90deg, #2365b2, #461bbb);
        @include mixins.MQ(mobile) {
          top: 2vh;
          left: -30vh;
          width: 120vh;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  
    .descriptionQ {
      font-size: 15.2px;
      line-height: 21px;
      @include mixins.MQ(mobile) {
        font-size: 13.2px;
        line-height: 16px;
        z-index: 9;
      }
    }
  
    :global(.bottomPart svg) {
      margin: 0 20px !important;
      height: 130px;
      @include mixins.MQ(mobile) {
        margin: 0 5px !important;
        height: 100px;
      }
    }
    .headPart {
      display: flex;
      color: white;
      justify-content: space-around;
    }
  
    @font-face {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      src:
        local("Open Sans"),
        local("OpenSans"),
        url("http://themes.googleusercontent.com/static/fonts/opensans/v5/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff")
          format("woff");
    }
  
    .descriptionQ,
    .title {
      width: 100%;
      max-width: 850px;
      color: #fff;
      top: 20vh;
      position: relative;
      @include mixins.MQ(mobile) {
        width: 100%;
        top: 0vh;
      }
    }
  
    .title {
      height: 60px;
      @include mixins.MQ(mobile) {
        height: 80px;
        z-index: 9;
        width: 90%;
      }
      h4 {
        line-height: 0px;
      }
      h1 {
        // font-family: sans-serif;
        font-size: 50px;
        // -webkit-text-stroke: 1px;
        // text-stroke: 1px;
        // -webkit-text-stroke-color: #ffffff;
        // -webkit-text-fill-color: #ffffff63;
        transform:translateY(-190px);
        @include mixins.MQ(mobile) {
          font-size: 30px;
          line-height: 30px;
          transform: translateY(60px);
        }
      }
    }
    .more {
      cursor: pointer;
      background: mixins.$color-1;
      padding: 10px 25px;
      z-index: 99;
      color: #fff;
      font-size: 20px;
      font-weight: 99;
      border-radius: 50px;
      border-bottom-right-radius: 50px;
      border-top-right-radius: 50px;
      width: 320px;
      margin: 0 auto;
      transform: scale(0.8);
      :global(svg) {
        margin-left: 30px;
      }
    }
  </style>
  