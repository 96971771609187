<script>
  import { navigateTo } from "yrv";
  import { onMount } from "svelte";
  import SVG from "@services/SVG.svelte";
  import { detectMob } from "@stores/app_store.js";
  import { router } from "yrv";
  import { get_lang } from "@stores/app_store.js";
  export let Player, langs;
</script>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .stats {
    border-top: 1px solid;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 18px;
    width: 100%;
    height: 100%;
    background: mixins.$Nwhite;
    @include mixins.MQ(mobile) {
      font-size: 13px;
    }
    h1 {
      width: 100%;
    }
    text-align: center;
    font-weight: 100;
    display: flex;
    .wrapper {
      padding: 0 20px;
      border-right: 1px solid mixins.$color-2;
      line-height: 24px;
      @include mixins.MQ(mobile) {  
      }
      &:last-child {
        border-right: 0px solid white;
      }
    }
  }
  .link_ {
    background: #fffefe;
    background: linear-gradient(45deg, #1c45a7, #1852bd 102%, #09f5ef);
    border: 3px solid white;
    border-radius: 22px;  
    padding: 5px 20px;
    font-weight: 200;
    a {
      color: white;
    }
  }
</style>

{#if Player && Player.Links && Player.Links.length != 0}
  <div class="stats zoom">
    <h1>
      <i class="fas fa-link" />
      {langs.PLinks}
    </h1>
    {#each Player.Links as item, i}
      <div class="wrapper zoom">
        <div class="link_">
          <a href={item.value.Link} target="_blanck">{item.value.Label}</a>
        </div>
      </div>
    {/each}
  </div>
{/if}
