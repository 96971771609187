<script>
  import { Data, get_lang } from "@stores/app_store.js";
  import { router } from "yrv";
  // $:console.log($router);
  let langs = $Data.langs[0];
</script>

{#if $router.path == "/"}
  <div class="center">
    <h2>{@html langs.arithmosMitroou}</h2>
  </div>
{:else if $router.path.includes("/player")}
  <div class="center">
    <h2>{@html langs.arithmosMitroou}</h2>
  </div>
{:else}
  <div class="center __ popup">
    <h2>{@html langs.Mfixtechnic}</h2>
    <h2>{@html langs.arithmosMitroou}</h2>
    <h1>G&F SCOUTER</h1>
    <h4>Football Players management</h4>
  </div>
{/if}

<style lang="scss">
  @use "../../styles/partial/mixins";
  .center {
    color: mixins.$white;
    font-size: 22px;
    overflow: hidden;
    height: 50px;
    text-shadow: 0 0 19px #000;
    text-align: center;
    width: 100%; 
    margin: 0;
    transition: height 1s;
    position: relative;
    top: 5px;
    background-size: 100% 600%;
    background-position: center;
    @include mixins.MQ(mobile) {
      margin: -10px 0;
      top: 0px; 
    }
    h1 {
      font-size: 65px;
      margin: 30px 0;
      @include mixins.MQ(mobile) {
        font-size: 46px;
        margin: 24px 0;
      }
    }
    h2 {
      font-size: 75%;
      @include mixins.MQ(mobile) {
        font-size: 3vw;
        margin-top: 0px;
      }
    }
  }
  :global(.blueslogan) {
    @include mixins.MQ(mobile) {
      height: 60px;
      margin: 0 !important;
      padding: 0 !important;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
    .center {
      @include mixins.MQ(mobile) {
        height: 30px;
        margin: 0 !important;
        padding: 0 !important;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        h2 {
          margin: 0 !important;
          padding: 0 !important;
          font-size: 3.4vw;
        }
      }
      h2 {
        color: #2976c7;
        text-shadow: 0 0 BLACK;
        font-family: Comfortaa, sans-serif;
      }
    }
  }
  .__ {
    height: 160px;
  }
</style>
