// SECTION IMPORTS
import axios from 'axios'
//import * as Comlink from 'comlink'
import Cookies from 'js-cookie'
// SECTION STATE
export const site_config = {
    siteUrl: 'https://www.gffootballplayers.com/' 
}
export const GetLang = () => {
    let ck = Cookies.get("Lang");
    return ck ? ck : "EL";
}
export const GetData = (lang) => {
    Cookies.set("Lang", lang, { expires: 300 });
    return new Promise(function(resolve, reject) {
        axios({
            method: 'get',
            url: site_config.siteUrl + 'CMS/api/collections/get/Languages?filter%5BID%5D=' + lang,
            params: {},
            data: {}
        }).then(entry => {
            axios({
                method: 'get',
                url: site_config.siteUrl + 'CMS/api/collections/get/Player_Categories',
                params: {},
                data: {}
            }).then(entry2 => {
                axios({
                    method: 'get',
                    url: site_config.siteUrl + 'CMS/api/collections/get/FootBallers',
                    params: { populate: 1, populate: 3 },
                    data: {}
                }).then(entry3 => {
                    axios({
                        method: 'get',
                        url: site_config.siteUrl + 'CMS/api/collections/get/News',
                        params: {},
                        data: {}
                    }).then(entry4 => {
                        axios({
                            method: 'get',
                            url: site_config.siteUrl + 'CMS/api/collections/get/Agents',
                            params: {},
                            data: {}
                        }).then(entry5 => {
                            axios({
                                method: 'get',
                                url: site_config.siteUrl + 'CMS/api/collections/get/Beltiosi_texnikis',
                                params: {},
                                data: {}
                            }).then(entry6 => {
                                axios({
                                    method: 'get',
                                    url: site_config.siteUrl + 'CMS/api/singletons/get/VERSION',
                                    params: {},
                                    data: {}
                                }).then(entry7 => {
                                    axios({
                                        method: 'get',
                                        url: site_config.siteUrl + 'CMS/api/collections/get/Idrites',
                                        params: {},
                                        data: {}
                                    }).then(entry8 => {
                                        axios({
                                            method: 'get',
                                            url: site_config.siteUrl + 'CMS/api/collections/get/Trainers',
                                            params: {},
                                            data: {}
                                        }).then(entry9 => {
                                            axios({
                                                method: 'get',
                                                url: site_config.siteUrl + 'CMS/api/collections/get/social',
                                                params: {},
                                                data: {}
                                            }).then(entry10 => {
                                                axios({
                                                    method: 'get',
                                                    url: site_config.siteUrl + 'CMS/api/singletons/get/Seo',
                                                    params: {},
                                                    data: {}
                                                }).then(entry11 => {
                                                    let data = {
                                                        langs: entry.data.entries,
                                                        submenu: entry2.data.entries,
                                                        players: entry3.data.entries,
                                                        News: entry4.data.entries,
                                                        Agents: entry5.data.entries,
                                                        Idrites: entry8.data.entries,
                                                        fixtechnic: entry6.data.entries,
                                                        trainers: entry9.data.entries,
                                                        social: entry10.data.entries[0],
                                                        Ver: entry7.data.VERSION,
                                                        Seo: entry11.data
                                                    };
                                                    resolve(data);
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });

            });
        })

    });

}