<script>
  import { navigateTo } from "yrv";
  import SVG from "@services/SVG.svelte";
  import { Data, get_lang } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let idrites = $Data.Idrites;
  let TopPart, header, body;

  // var observer = new IntersectionObserver(
  //   ([entry]) => {
  //     if (entry.intersectionRatio !== 0) {
  //       header.classList.add("popup");
  //       body.classList.add("popupslow");
  //     }
  //   },
  //   {
  //     threshold: [0, 1],
  //   }
  // );
  // onMount(() => {
  //   observer.observe(TopPart);
  // });
</script>

<div class="fullWidth inner">
  <div class="title popup">
    <h1>
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="
      width: 80px;
      fill: white;
  "
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier"
          ><path
            d="M4 21V19.5C4 16.4624 6.46243 14 9.5 14H12.5C15.5376 14 18 16.4624 18 19.5V21M7 21V18M15 21V18M16.5 6L16.8367 5.49493C17.1969 4.95461 17.9371 4.82782 18.4566 5.21745C19.0073 5.63047 19.0645 6.43549 18.5778 6.92224L17.8536 7.64645C17.6272 7.87282 17.5 8.17986 17.5 8.5M17.5 10V10.2M13.8281 4.89801C14.6435 3.74945 15.9842 3 17.5 3C19.9853 3 22 5.01472 22 7.5C22 9.98528 19.9853 12 17.5 12C16.2549 12 15.1279 11.4943 14.3131 10.6771M15 8.00001C15 5.79086 13.2091 4 11 4C8.79086 4 7 5.79086 7 8.00001C7 10.2092 8.79086 12 11 12C11.8312 12 12.6032 11.7465 13.2429 11.3125C14.3033 10.5931 15 9.37794 15 8.00001Z"
            stroke="#1034a6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.4"
          ></path></g
        ></svg
      >
      {@html langs.questionsSection.title}
    </h1>
  </div>
  <div class="descriptionQ appearleft">
    {@html langs.questionsSection.description}
    <div
      class="more fadein"
      on:click={() => {
        navigateTo("/questions", { replace: true });
      }}
    >
      {langs.questionsSection.button}
      <SVG key={"arrowright"} width={"10px"} />
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  .inner {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    top: -16px;
    justify-content: center;
    margin-bottom: 50px;
    text-align: center;
    align-content: center;
    align-items: center;
    background-position: bottom;
    height: 100%;
    background-size: cover;
    @include mixins.MQ(mobile) {
      top: 17px;
    }
  }

  .descriptionQ {
    font-size: 15.2px;
    line-height: 21px;
    @include mixins.MQ(mobile) {
      margin-top: 10px;
      font-size: 13.2px;
      line-height: 16px;
    }
  }

  .title h4 {
    line-height: 0px;
  }

  .descriptionQ,
  .title {
    width: 90%;
    max-width: mixins.$maxWidth;
  }

  :global(.bottomPart svg) {
    margin: 0 20px !important;
    height: 130px;
    @include mixins.MQ(mobile) {
      margin: 0 5px !important;
      height: 100px;
    }
  }
  .headPart {
    display: flex;
    color: white;
    justify-content: space-around;
  }
  .title h1 {
    font-size: 50px;
    line-height: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 15px;
    @include mixins.MQ(mobile) {
      font-size: 25px;
      line-height: 60px;
      svg {
        width: 70px !important;
      }
      @include mixins.BP(Ms) {
        font-size: 15px !important;
        margin-top: 20px;
      }
      @include mixins.BP(Mm) {
        font-size: 15px !important;
        margin-top: 20px;
      }
    }
  }
  .title {
    height: 110px;
    @include mixins.MQ(mobile) {
      height: 80px;
    }
  }
  .more {
    cursor: pointer;
    background: mixins.$color-1;
    padding: 10px 25px;
    z-index: 99;
    color: #fff;
    font-size: 14px;
    font-weight: 99;
    border-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    max-width: 280px;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    display: flex;
    :global(svg) {
      margin-left: 30px;
    }
  }
</style>
