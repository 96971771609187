<script>
  import { onMount } from "svelte";
  const root = document.documentElement;
  const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue(
    "--marquee-elements-displayed"
  );
  let marqueeContent = null;
  onMount(() => {
    root.style.setProperty(
      "--marquee-elements",
      marqueeContent.children.length
    );

    for (let i = 0; i < marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }
  });
</script>

<div class="marquee">
  <ul class="marquee-content" bind:this={marqueeContent}>
    <li>
      <a href="https://www.fifa.com/" target="_black">
        <div class="socialBox">
          <img
            src="https://www.gffootballplayers.com/sponsors/ff.png"
            alt="FIFA"
            width="49px"
          />
        </div>
      </a>
    </li>
    <li>
      <a href="https://www.uefa.com/" target="_black">
        <div class="socialBox">
          <img
            src="https://www.gffootballplayers.com/sponsors/uefa.png"
            alt="UEFA"
            width="40px"
          />
        </div>
      </a>
    </li>
    <!-- ----- -->
    <li>
      <a href="https://www.the-afc.com/en/home.html" target="_black">
        <div class="socialBox">
          <img src="/images/sponsors/AFC-Logo.png" alt="AFC" width="40px" />
        </div>
      </a>
    </li>
    <li>
      <a href="https://www.cafonline.com/" target="_black">
        <div class="socialBox">
          <img src="/images/sponsors/CAF.svg" alt="CAF" width="40px" />
        </div>
      </a>
    </li>
    <li>
      <a href="https://www.concacaf.com/" target="_black">
        <div class="socialBox">
          <img
            src="/images/sponsors/Concacaf_logo.svg.png"
            alt="Concacaf"
            width="40px"
          />
        </div>
      </a>
    </li>
    <li>
      <a href="https://www.conmebol.com/" target="_black">
        <div class="socialBox">
          <img
            src="/images/sponsors/Conmebol_2017.webp"
            alt="conmebol"
            width="40px"
          />
        </div>
      </a>
    </li>
    <li>
      <a href="https://www.oceaniafootball.com/" target="_black">
        <div class="socialBox">
          <img src="/images/sponsors/OFC.png" alt="OFC" width="40px" />
        </div>
      </a>
    </li>
    <!-- <li>
      <a href="https://www.epo.gr/Home.aspx?a_id=256" target="_black">
        <div class="socialBox">
          <img
            alt="EPO"
            src="https://www.gffootballplayers.com/sponsors/epo.png"
            width="40px" />
        </div>
      </a>
    </li> -->
  </ul>
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";

  :root {
    --marquee-width: 100vw;
    --marquee-height: 110px;
    /* --marquee-elements: 12; */ /* defined with JavaScript */
    --marquee-elements-displayed: 8;
    --marquee-element-width: calc(
      var(--marquee-width) / var(--marquee-elements-displayed)
    );
    --marquee-animation-duration: calc(var(--marquee-elements) * 4s);
  }

  .marquee {
    width: var(--marquee-width);
    max-width: 1350px;
    height: var(--marquee-height);
    color: #eee;
    overflow: hidden;
    position: relative;
    top: 50px;
    @include mixins.MQ(mobile) {
      top: 56px;
    }
    left: 50%;
    transform: translateX(-50%);
  }

  .marquee-content {
    list-style: none;
    height: 90%;
    
    display: flex;
    animation: scrolling var(--marquee-animation-duration) linear infinite;
  }
  .marquee-content:hover {
    animation-play-state: paused;
  }
  @keyframes scrolling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        calc(-1 * var(--marquee-element-width) * var(--marquee-elements))
      );
    }
  }
  .marquee-content li {
    align-items: center;
    display: flex;
    justify-content: center;
    // align-items: flex-end;
    flex-shrink: 0;
    width: var(--marquee-element-width);
    max-height: 80%;
    font-size: calc(var(--marquee-height) / 2.5); /* 5rem; */
    white-space: nowrap;
  }

  .marquee-content li img {
    width: 100%;
    border: 2px solid #eee;
  }

  @media (max-width: 600px) {
    html {
      font-size: 12px;
    }
    :root {
      --marquee-width: 100vw;
      --marquee-elements-displayed: 2;
    }
    .marquee:before,
    .marquee:after {
      width: 5rem;
    }
  }
  .marquee-content {
    height: 100% !important;
  }
  .socialBox {
    width: 120px;
    // transform: translateY(5px);
    align-items: center;
    border: none;
    outline: none;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 85px;
    // background: #fff;
    // border-radius: 10px;
    filter: drop-shadow(4px 0px 0px white) drop-shadow(-2px 2px 1px white) drop-shadow(-2px -2px 0px cyan);
    img {
      width: 100% !important;
      border: none !important;
      outline: none !important;
      object-fit: contain;
      height: 100%;
      padding: 10px;
    }
  }
</style>
