<script>
  import List from "./playerslist.svelte";
  // import Thumbnail from "./thumbnails.svelte";
  import ThumbnailLeft from "./thumbnailsLeft.svelte";
  import Stats from "./stats.svelte";
  import PrevTeam from "./PrevTeams.svelte";
  import PhotoVideo from "./PhotoVideo.svelte";
  import Links from "./Links.svelte";
  import GalleryViewer from "./GalleryPlayers.svelte";
  import { onMount } from "svelte";
  import { router } from "yrv";
  import { Data } from "@stores/app_store.js";
  let langs = $Data.langs[0];
  let players = $Data.players;
  let extrafilter = $router.query;
  let searchQuery = "";

  if (!extrafilter.cat) {
    extrafilter = "";
  }
  let isGalleryOpen = false,
    currentframe = 0;
  players = players.filter(function (value, index, arr) {
    return !players[index].DEMO;
  });
  if (Object.keys(extrafilter).length != 0) {
    players = players.filter(function (value, index, arr) {
      return players[index].Category.ID == extrafilter.cat;
    });
  }
  let trigger, trigger_bottom, list, bottomButtons, searchinput;
  var observer = new IntersectionObserver(
    ([entry]) => {
      if (!list) return;
      if (entry.intersectionRatio !== 0) {
        searchinput.classList.remove("sticked");
        list.classList.remove("sticked");
      } else {
        searchinput.classList.add("sticked");
        list.classList.add("sticked");
      }
    },
    {
      threshold: [0, 1],
    }
  );
  var observer_bottom = new IntersectionObserver(
    ([entry]) => {
      if (!list) return;
      if (!bottomButtons) return;
      if (entry.intersectionRatio !== 0) {
        bottomButtons.classList.add("sticked_bottom");
        list.classList.add("sticked_bottom");
      } else {
        bottomButtons.classList.remove("sticked_bottom");
        list.classList.remove("sticked_bottom");
      }
    },
    {
      threshold: [0, 1],
    }
  );
  onMount(() => {
    observer.observe(trigger);
    observer_bottom.observe(trigger_bottom);
    document.querySelector("#Main").scrollTop = 0;
    // Update button state on initial load
    updateButtonState();
    if (players.length != 0) {
      // Add event listener to update button state on scroll
      list.addEventListener("scroll", updateButtonState);
    }
  });

  let div_mobile_show_players = true;
  let scrollUpDisabled = true;
  let scrollDownDisabled = false;

  function mobile_show_players() {
    div_mobile_show_players.classList.add("active");
  }

  function mobile_hide_players() {
    div_mobile_show_players.classList.remove("active");
    document.querySelector("#Main").scrollTop = 0;
  }

  function handleScroll(direction) {
    if (!list) return;
    const scrollAmount = window.innerHeight;
    if (direction === "up") {
      list.scrollBy({ top: -scrollAmount, behavior: "smooth" });
    } else if (direction === "down") {
      list.scrollBy({ top: scrollAmount, behavior: "smooth" });
    }
    updateButtonState();
  }
  function updateButtonState() {
    if (!list) return;
    scrollUpDisabled = list.scrollTop === 0;
    scrollDownDisabled =
      list.scrollTop + list.clientHeight >= list.scrollHeight;
  }

  $: filteredPlayers = players.filter(
    (player) =>
      player.OnomaEN.toLowerCase().includes(searchQuery.toLowerCase()) ||
      player.OnomaEL.toLowerCase().includes(searchQuery.toLowerCase()) ||
      player.OnomaSP.toLowerCase().includes(searchQuery.toLowerCase())
  );
</script>

{#if isGalleryOpen}
  <GalleryViewer
    Player={players[players.findIndex((x) => x._id === $router.params.slug)]}
    {currentframe}
    on:Close={() => {
      isGalleryOpen = false;
    }}
  />
{/if}
<div class="wrapperPlayers">
  <div class="fullWidth Trigger" bind:this={trigger} />

  <button class="mobile_show_players" on:click={() => mobile_show_players()}
    >All players<svg
      style="width: 16px;height: 17px;transform: rotate(180deg);"
      fill="#ffffff"
      width="64px"
      height="64px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ffffff"
      ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g><g id="SVGRepo_iconCarrier">
        <path
          d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"
        ></path>
      </g></svg
    ></button
  >

  <div class="leftPlayers" bind:this={div_mobile_show_players}>
    {#if players.length != 0}
      <input
        type="text"
        placeholder="Search players..."
        bind:value={searchQuery}
        class="search-input"
        bind:this={searchinput}
      />
      <div class="fullWidth plist" bind:this={list}>
        <ThumbnailLeft
          players={filteredPlayers}
          {langs}
          on:playerClicked={() => mobile_hide_players()}
        />
      </div>
      <div class="scroll-buttons" bind:this={bottomButtons}>
        <button
          class="scrollUp"
          on:click={() => handleScroll("up")}
          disabled={scrollUpDisabled}
          ><svg
            style=" width: 20px;
          transform: rotate(180deg);"
            fill="#ffffff"
            width="64px"
            height="64px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ffffff"
            ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g><g id="SVGRepo_iconCarrier">
              <path
                d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"
              ></path>
            </g></svg
          ></button
        >
        <button
          class="scrollDown"
          on:click={() => handleScroll("down")}
          disabled={scrollDownDisabled}
          ><svg
            style=" width: 20px; "
            fill="#ffffff"
            width="64px"
            height="64px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ffffff"
            ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g><g id="SVGRepo_iconCarrier">
              <path
                d="M0.256 8.606c0-0.269 0.106-0.544 0.313-0.75 0.412-0.412 1.087-0.412 1.5 0l14.119 14.119 13.913-13.912c0.413-0.412 1.087-0.412 1.5 0s0.413 1.088 0 1.5l-14.663 14.669c-0.413 0.413-1.088 0.413-1.5 0l-14.869-14.869c-0.213-0.213-0.313-0.481-0.313-0.756z"
              ></path>
            </g></svg
          ></button
        >
      </div>
    {/if}
  </div>
  <div class="rightPlayers">
    <div class="fullWidth section1">
      <List {players} {langs} />
    </div>
    <div class="fullWidth section2">
      <Stats
        playerTimeline={players[
          players.findIndex((x) => x._id === $router.params.slug)
        ]}
        {langs}
      />
    </div>
    <div class="fullWidth section3">
      <PrevTeam
        Player={players[
          players.findIndex((x) => x._id === $router.params.slug)
        ]}
        {langs}
      />
    </div>

    <div class="fullWidth section4">
      <Links
        Player={players[
          players.findIndex((x) => x._id === $router.params.slug)
        ]}
        {langs}
      />
    </div>

    <div class="fullWidth section5">
      <PhotoVideo
        Player={players[
          players.findIndex((x) => x._id === $router.params.slug)
        ]}
        {langs}
        on:Open={(event) => {
          currentframe = event.detail.cf;
          isGalleryOpen = true;
        }}
      />
    </div>
    {#if players.length != 1}
      <!-- <div
        class="fullWidth sectionN sticked"
        
        bind:this={list}
      >
        <Thumbnail {players} {langs} /> 
      </div> -->
    {/if}
  </div>
  <div class="fullWidth Trigger_bottom" bind:this={trigger_bottom} />
</div>

<style lang="scss">
  @use "../../../styles/partial/mixins";
  .mobile_show_players {
    background: #13418c;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: fixed;
    z-index: 9;
    bottom: 0;
    gap: 20px;
    @include mixins.MQ(mobile) {
      gap: 5px;
    }
    flex-direction: row;
    padding: 5px;
    border-radius: 100px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;
    font-size: 17px;
    border-top: 1px solid white;
    @include mixins.MQ(pc) {
      display: none;
    }
  }
  .search-input {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 10px;
    margin-bottom: 20px;
    margin-top: 15px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 74px;
    z-index: 2;
    left: 1.5%;
    box-shadow: 0 0 20px rgb(0 0 0 / 37%);
    transition: top 1s;
  }

  :global(.search-input.sticked) {
    @include mixins.MQ(pc) {
      position: fixed;
      width: 27%;
      margin: 0;
      top: 63px;
    }
  }

  .wrapperPlayers {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .leftPlayers {
    width: 30%;
    background: #dfeeff;
    @include mixins.MQ(mobile) {
      position: fixed;
      width: 100%;
      z-index: 9;
      transition:
        top 0.5s ease-in-out,
        opacity 0.5s ease-in-out;
      top: 800px;
      height: 86vh;
      opacity: 0;
    }
  }
  :global(.leftPlayers.active) {
    top: 124px;
    opacity: 1;
  }

  .rightPlayers {
    width: 70%;
    max-width: 1350px;
    @include mixins.MQ(mobile) {
      width: 100%;
    }
  }
  .scroll-buttons {
    @include mixins.MQ(pc) {
      position: fixed;
      bottom: 0;
      left: 0;
    }
    background: rgb(255, 255, 255);
    border-top: 2px solid;
    width: 30%;
    z-index: 9;
    display: flex;
    height: 40px;
    justify-content: space-between;
  }

  :global(.scroll-buttons.sticked_bottom) {
    @include mixins.MQ(pc) {
      position: absolute;
      bottom: 0;
    }
  }

  .scrollUp,
  .scrollDown {
    width: 50%;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    background-color: #1034a6;
    color: #fff;
    border: none;
    font-weight: 700;
    display: flex;
    transition:
      background-color 0.3s ease,
      opacity 0.3s ease;
    align-items: center;
    justify-content: center;
    align-content: center;

    &:hover {
      background-color: #0056b3;
    }

    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
      opacity: 0.6;
    }

    &:not(:disabled) {
      opacity: 1;
    }
  }

  .fullWidth.plist {
    position: relative;
    top: 0;
    transition: top 0.1s ease-in-out;
    height: 76vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
  }

  :global(.fullWidth.plist.sticked) {
    @include mixins.MQ(pc) {
      position: fixed;
      width: 30%;
      top: 60px;
      left: 0;
      height: 90vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 50px;
      padding-bottom: 0px;
    }
  }

  :global(.fullWidth.plist.sticked_bottom) {
    @include mixins.MQ(pc) {
      position: absolute;
      width: 30%;
      bottom: 0;
      left: 0;
      top: unset;
      height: 94vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-bottom: 40px;
    }
  }
  .Trigger {
    width: 100%;
    height: 20px;
    margin: -10px 0;
  }
  .fullWidth.section1 {
    height: 690px;
    @include mixins.MQ(mobile) {
      height: 780px;
    }
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  .fullWidth.section2 {
    background: mixins.$Nwhite;
    position: relative;
    z-index: 2;
  }
  .fullWidth.section3 {
    background: mixins.$Nwhite;
    position: relative;
    z-index: 3;
  }
  .fullWidth.section4 {
    background: mixins.$Nwhite;
    position: relative;
    z-index: 4;
  }
  .fullWidth.section5 {
    background: mixins.$Nwhite;
    position: relative;
    z-index: 5;
  }
  .fullWidth.sectionN {
    position: absolute;
    top: 0px;
    height: 110px;
    scroll-behavior: smooth;
    z-index: 999;
  }
  .fullWidth.sectionN.sticked {
    height: 110px;
    width: mixins.$maxWidth;

    position: fixed !important;
    bottom: -5px;
    left: calc(50% - ((1280px) / 2)+5);
    @include mixins.MQ(mobile) {
      width: 100%;
      left: 0;
    }
    overflow: hidden;
    overflow-x: scroll;
    z-index: 999;
  }
</style>
